// jQuery free
import {onDOMReady} from './dom';

onDOMReady(function () {
    const checkbox = document.getElementById('shoppingCartPartialDelivery__checkbox');

    if (!checkbox) {
        return;
    }

    const customFieldUrl = '/api/1/cart/custom-field';

    fetch(customFieldUrl + '?names[0]=partialDelivery', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then(response => response.json())
        .then(data => {
            checkbox.checked = data.length === 0 || data[0].value === '0';
            checkbox.addEventListener('change', function () {
                const isChecked = this.checked;

                fetch('/api/1/cart/custom-field/', {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify([{name: 'partialDelivery', value: !isChecked}])
                });
            });
        });
});
