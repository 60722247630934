import {onDOMReady} from './dom';

onDOMReady(() => {
    const accordions = document.querySelectorAll('.accordion__title')
    const accordionItems = document.querySelectorAll('.accordion');

    const lastAccordionItem = accordionItems[accordionItems.length - 1];
    if (lastAccordionItem) {
        lastAccordionItem.style.borderBottom = 'none'; // Remove border from last accordion item
    }

    accordions.forEach((accordion) => {
        accordion.addEventListener('click', function () {
            const content = this.nextElementSibling;
            const isExpanded = this.getAttribute('aria-expanded') === 'true';

            accordions.forEach((otherAccordion) => {
                const otherContent = otherAccordion.nextElementSibling;
                otherAccordion.setAttribute('aria-expanded', 'false');
                otherContent.hidden = true;
            });

            this.setAttribute('aria-expanded', !isExpanded);
            content.hidden = isExpanded;
        });
    });
});
