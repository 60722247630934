// jQuery free
import {lock, unlock} from 'tua-body-scroll-lock';

(() => {
    document.addEventListener(window.km_modal.EVENT_KM_MODAL_SHOW_MODAL, function (event) {
        // Add small dealy because of wrong order lock/unlock
        setTimeout(() => {
            lock(event.detail.dialogElement);
        }, 100);

    });
    document.addEventListener(window.km_modal.EVENT_KM_MODAL_DESTROY, function (event) {
        setTimeout(() => {
            unlock(event.detail.dialogElement);
        }, 300);
    });
})();