// jQuery free
import {createPopper} from '@popperjs/core/lib/popper-lite';
import preventOverflow from '@popperjs/core/lib/modifiers/preventOverflow';
import offset from '@popperjs/core/lib/modifiers/offset';
import {clearBodyLocks, lock} from 'tua-body-scroll-lock';
import {onDOMReady} from './dom';

const DATA_DROPDOWN_INIT = 'data-dropdown-init';
const DATA_DROPDOWN_ID = 'data-dropdown-id';
const DATA_DROPDOWN_PLACEMENT = 'data-dropdown-placement';
const DATA_DROPDOWN_DISABLE_BODY_SCROLL = 'data-dropdown-disable-body-scroll';
const DATA_DROPDOWN_DELAY = 'data-dropdown-delay';
export const DATA_DROPDOWN_ON_HOVER = 'data-dropdownonhover';
const ID_LAYOUT_HEADER = 'layoutHeader';
const CLASS_ACTIVE = '--active';
const CLASS_OVERLAY_ACTIVE = '--overlayActive';
const CLASS_OVERLAY_DISABLE = '--overlayDisabled';
const CLASS_DROPDOWN_BACKDROP = 'dropdown__backdrop';
const DELAY = 200;

export const EVENT_DROPDOWN_OPEN = 'dropdownOpen';
export const EVENT_DROPDOWN_CLOSE = 'dropdownClose';


/**
 * @type {Dropdown[]}
 */
const dropdowns = [];

/**
 * @type {Dropdown|null}
 */
let openedDropdown = null;

/**
 * @returns {Dropdown|null}
 */
export const getOpenedDropdown = () => {
    return openedDropdown;
};

const closeOpenedDropdown = (immediate = false) => {
    if (openedDropdown) {
        openedDropdown.close(immediate);
    }
};

/**
 * @param {HTMLElement} referenceElement
 * @param {HTMLElement} dropdownElement
 */
export function createDropdown(referenceElement, dropdownElement) {
    new Dropdown(referenceElement, dropdownElement);
}



/**
 * @property {HTMLElement} referenceElement
 * @property {HTMLElement} dropdownElement
 * @property {HTMLElement} dropdownContentElement
 */
class Dropdown {
    /**
     * @param {HTMLElement} referenceElement
     * @param {HTMLElement} dropdownElement
     */
    constructor(referenceElement, dropdownElement) {
        dropdowns.push(this);
        this.popperInstance = null;
        this.referenceElement = referenceElement;
        this.dropdownElement = dropdownElement;
        this.dropdownContentElement = dropdownElement.children[0];
        this.isReferenceElementInHeader = Boolean(this.referenceElement.closest('#' + ID_LAYOUT_HEADER));
        this.delay = (this.isReferenceElementInHeader || this.referenceElement.hasAttribute(DATA_DROPDOWN_DELAY)) ? DELAY : 0;
        this.disableBodyScroll = this.isReferenceElementInHeader || this.referenceElement.hasAttribute(DATA_DROPDOWN_DISABLE_BODY_SCROLL);
        this.isInput = referenceElement.nodeName === 'INPUT';
        this.isOnHover = referenceElement.hasAttribute(DATA_DROPDOWN_ON_HOVER);
        this.triggerEventName = this.isInput ? 'focus' : 'click';
        if (this.isOnHover) {
            this.triggerEventName = 'mouseover';
        }
        referenceElement.addEventListener(this.triggerEventName, (event) => {
            event.preventDefault();
            event.stopPropagation();

            if (!this.isActive()) {
                this.open();
            } else if (!this.isInput && !this.isOnHover) {
                this.close();
            }
        });
    }

    isForID(id) {
        return (this.dropdownElement.id && this.dropdownElement.id === id) || (this.referenceElement.id && this.referenceElement.id === id);
    }

    triggerOpen() {
        if (!this.isActive()) {
            this.referenceElement.focus();
            if (!this.isInput) {
                this.referenceElement.click();
            }
        }
    }

    showBackdrop() {
        document.body.classList.add(CLASS_OVERLAY_ACTIVE);
        this.isOnHover
            ? document.body.classList.add(CLASS_OVERLAY_DISABLE)
            : document.body.classList.remove(CLASS_OVERLAY_DISABLE);
    }

    static hideBackdrop() {
        document.body.classList.remove(CLASS_OVERLAY_ACTIVE);
        document.body.classList.remove(CLASS_OVERLAY_DISABLE);
    }

    open() {
        this.showBackdrop();

        setTimeout(() => {
            closeOpenedDropdown();
            this.showBackdrop();

            openedDropdown = this;
            this.popperInstance = createPopper(this.referenceElement, this.dropdownElement, {
                placement: this.referenceElement.getAttribute(DATA_DROPDOWN_PLACEMENT) || 'bottom-start',
                modifiers: [{
                    ...preventOverflow,
                    options: {
                        tether: false
                    }
                }, {
                    ...offset,
                    options: {
                        offset: [0, 1]
                    }
                }]
            });

            this.dropdownElement.classList.add(CLASS_ACTIVE);
            this.referenceElement.classList.add(CLASS_ACTIVE);

            if (document.activeElement === this.referenceElement && !(this.referenceElement instanceof HTMLInputElement)) {
                document.activeElement.blur();
            }

            setTimeout(() => {
                this.dropdownElement.style.height = 'auto';
                if (this.disableBodyScroll) {
                    lock(this.dropdownContentElement);
                    const referenceClientRects = this.popperInstance.state.elements.reference.getClientRects()[0];
                    const dropdownOffsetHeight = referenceClientRects.top + referenceClientRects.height;
                    const height = window.innerHeight - dropdownOffsetHeight;
                    console.debug(this.popperInstance, window.innerHeight, referenceClientRects, dropdownOffsetHeight, height);
                    //this.dropdownElement.style.height = height + 'px';
                    this.dropdownElement.style.height = `calc(100dvh - ${dropdownOffsetHeight}px)`;
                }
                this.dropdownContentElement.classList.add(CLASS_ACTIVE);
            }, 50);

            try {
                document.dispatchEvent(new CustomEvent(EVENT_DROPDOWN_OPEN, {
                    detail: {
                        dropdownElement: this.dropdownElement,
                        referenceElement: this.referenceElement,
                        dropdown: this
                    }
                }));
            } catch (e) {
            }
        }, this.delay);
    }

    close(immediate = false) {
        this.referenceElement.classList.remove(CLASS_ACTIVE);
        this.dropdownElement.children[0].classList.remove(CLASS_ACTIVE);
        openedDropdown = null;

        Dropdown.hideBackdrop();
        clearBodyLocks();

        setTimeout(() => {
            this.dropdownElement.classList.remove(CLASS_ACTIVE);
            if (this.popperInstance) {
                this.popperInstance.destroy();
            }
            this.popperInstance = null;
        }, immediate ? 0 : 250);

        try {
            document.dispatchEvent(new CustomEvent(EVENT_DROPDOWN_CLOSE, {
                detail: {
                    dropdownElement: this.dropdownElement,
                    referenceElement: this.referenceElement,
                    dropdown: this
                }
            }));
        } catch (e) {
        }
    }

    isActive() {
        return this.referenceElement.classList.contains(CLASS_ACTIVE);
    }


    isElementInsideDropdownElement(element) {
        const dropdownContentElement = this.dropdownContentElement;
        return dropdownContentElement === element || dropdownContentElement.contains(element);
    }

    isElementInsideReferenceElement(element) {
        const referenceElement = this.referenceElement;
        return referenceElement === element || referenceElement.contains(element);
    }

}



window.addEventListener('click', (event) => {
    const targetElement = event.target;
    if (openedDropdown) {
        if (!openedDropdown.isElementInsideDropdownElement(targetElement) && !openedDropdown.isElementInsideReferenceElement(targetElement)) {
            closeOpenedDropdown();
        }
    }
});
let lastWindowWidth = window.innerWidth;



window.addEventListener('resize', () => {
    if (window.innerWidth !== lastWindowWidth) {
        closeOpenedDropdown();
        lastWindowWidth = window.innerWidth;
    }
}, {passive: true});



function locationHashChange() {
    console.debug(location.hash);
    const id = location.hash.slice(1);
    if (!id) {
        return;
    }

    for (const dropdown of dropdowns) {
        if (dropdown && dropdown.isForID(location.hash.slice(1))) {
            dropdown.triggerOpen();
            location.hash = '';
            break;
        }
    }
}



/**
 * @param {HTMLElement} referenceElement
 */
function replaceRtlPlacement(referenceElement) {
    let placement = referenceElement.getAttribute(DATA_DROPDOWN_PLACEMENT) || 'bottom-start';
    const replacements = {
        end: 'start',
        start: 'end'
    };
    placement = placement.replace(/start|end/g, matched => replacements[matched]);
    referenceElement.setAttribute(DATA_DROPDOWN_PLACEMENT, placement);
}



onDOMReady(() => {
    const referenceElements = document.querySelectorAll(`[${DATA_DROPDOWN_ID}]`);
    const isRtl = document.documentElement.getAttribute('dir') === 'rtl';

    for (const referenceElement of referenceElements) {
        if (referenceElement.hasAttribute(DATA_DROPDOWN_INIT)) {
            continue;

        }
        if (isRtl) {
            replaceRtlPlacement(referenceElement);
        }

        /** @type HTMLElement */
        const dropdownElement = document.getElementById(referenceElement.dataset.dropdownId);
        createDropdown(referenceElement, dropdownElement);
    }

    document.addEventListener(window.km_modal.EVENT_KM_MODAL_SHOW_MODAL_BEFORE, function () {
        closeOpenedDropdown(true);
    });


    window.addEventListener('hashchange', locationHashChange);
    locationHashChange();
});

// jQuery: few selector usages remaining