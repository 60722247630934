// jQuery free
import {onDOMReady} from './dom';

onDOMReady(function () {
    const forms = document.getElementsByClassName('shoppingCartLoginOrRegister__form');

    if (!forms.length) {
        return;
    }

    for (const form of forms) {
        form.addEventListener('submit', function (event) {
            event.preventDefault();

            const spinnerWrapper = document.createElement('div');
            spinnerWrapper.className = 'shoppingCartLoginOrRegister__spinnerWrapper';
            spinnerWrapper.innerHTML = km_spinner.html;

            Array.from(form.children).forEach(child => {
                child.style.visibility = 'hidden';
            });

            form.appendChild(spinnerWrapper);

            const formData = new FormData(form);
            const params = new URLSearchParams(formData);

            fetch('/api/1/user/login', {
                method: 'POST',
                body: params,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })
                .then(response => {
                    if (!response.ok) {
                        return response.json().then(data => {
                            throw data;
                        });
                    }
                    location.reload();
                })
                .catch(data => {
                    spinnerWrapper.remove();
                    Array.from(form.children).forEach(child => {
                        child.style.visibility = 'visible';
                    });

                    let response = data;
                    let element;

                    if (response.code === 7 || response.code === 2) {
                        element = document.getElementById('shoppingCartLoginOrRegister-login-password');
                    } else {
                        element = document.getElementById('shoppingCartLoginOrRegister-login-email');
                    }

                    Nette.addError(element, response.userMessage);
                });
        });
    }
});
