// jQuery free
import {onDOMReady} from './dom';

const CLASS_SCROLLX_INIT = 'scrollX';

const DATA_SCROLLX_INIT = 'data-scrollx-init';

const SCROLL_THRESHOLD = 24;


const createScrollX = function (element) {
    if (element.hasAttribute(DATA_SCROLLX_INIT)) {
        return;
    }

    element.setAttribute(DATA_SCROLLX_INIT, '1');
    const scrollable = element.children[0];
    const arrowStart = element.children[1];
    const arrowEnd = element.children[2];


    const updateArrowVisibility = function () {
        const isScrolledToStart = scrollable.scrollLeft < SCROLL_THRESHOLD;
        const isScrolledToEnd = (scrollable.scrollWidth - scrollable.offsetWidth - scrollable.scrollLeft) < SCROLL_THRESHOLD;
        arrowStart.disabled = isScrolledToStart;
        arrowEnd.disabled = isScrolledToEnd;
    };


    arrowStart.addEventListener('click', function () {
        if (scrollable.scrollTo) {
            scrollable.scrollTo({left: 0, behavior: 'smooth'});
        } else {
            scrollable.scrollLeft = 0;
        }
    });

    arrowEnd.addEventListener('click', function () {
        const left = 1000; //scrollable.scrollWidth - scrollable.offsetWidth;
        if (scrollable.scrollTo) {
            scrollable.scrollTo({left: left, behavior: 'smooth'});
        } else {
            scrollable.scrollLeft = left;
        }
    });

    // const activeElement = element.querySelector('.--current, .--active, .--selected');
    // if (activeElement && activeElement.scrollIntoView) {
    //     activeElement.scrollIntoView();
    // }

    updateArrowVisibility();
    window.addEventListener('resize', updateArrowVisibility, {passive: true, capture: false});
    scrollable.addEventListener('scroll', updateArrowVisibility, {passive: true, capture: false});
};


onDOMReady(function () {
    for (const element of document.getElementsByClassName(CLASS_SCROLLX_INIT)) {
        createScrollX(element);
    }
});