// uses jQuery
import {onDOMReady} from './dom';

onDOMReady(() => {
    document.addEventListener('KMDeliveryPaymentAddressControl.deliveryMethodChange', function (event) {
        const missingPriceToFreeDeliveryDiv = document.getElementsByClassName('missingPriceToFreeDelivery')[0];
        const missingPriceToFreeDeliveryPriceSpan = document.getElementsByClassName('missingPriceToFreeDelivery__price')[0];
        const dataObject = event.detail;

        if (dataObject.hasOwnProperty('kvikymartMisssingPriceToFree') && dataObject.checked === true) {
            missingPriceToFreeDeliveryDiv.classList.add('missingPriceToFreeDelivery--active');
            missingPriceToFreeDeliveryPriceSpan.innerHTML = dataObject.kvikymartMisssingPriceToFree;
        } else {
            missingPriceToFreeDeliveryDiv.classList.remove('missingPriceToFreeDelivery--active');
        }
    });

    if (window.KMDeliveryPaymentAddressControl && window.KMDeliveryPaymentAddressControl.dispatchDeliveryMethodChangeEvent) {
        window.KMDeliveryPaymentAddressControl.dispatchDeliveryMethodChangeEvent();
    }
});