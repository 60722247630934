// jQuery free
import {onDOMReady} from './dom';
import animatedScrollTo from 'animated-scrollto';

const QUERY_SELECTOR = '.shoppingCartLoginRegisterContinue__link';
const CLASS_MODIFIER_ACTIVE = '--active';
const CLASS_MODIFIER_CONTINUE = '--continue';

const initShoppingCartLoginRegisterContinue = () => {
    const linksAll = document.querySelectorAll(QUERY_SELECTOR);
    const linkContinue = document.querySelector(QUERY_SELECTOR + '.' + CLASS_MODIFIER_CONTINUE);
    const indexOfHash = linkContinue?.getAttribute('href')?.indexOf('#');
    if (indexOfHash !== 0) {
        return;
    }

    for (const link of linksAll) {
        link.addEventListener('click', () => {
            for (const link of linksAll) {
                link.classList.remove(CLASS_MODIFIER_ACTIVE);
            }
        });
    }

    const target = document.getElementById(linkContinue.getAttribute('href')?.substring(indexOfHash + 1));
    linkContinue.addEventListener('click', (event) => {
        event.preventDefault();
        linkContinue.classList.add(CLASS_MODIFIER_ACTIVE);
        // target.scrollIntoView({
        //     behavior: 'smooth'
        // });
        animatedScrollTo(
            document.documentElement,
            target.getBoundingClientRect().top + document.documentElement.scrollTop,
            500,
        );
    });
};

onDOMReady(initShoppingCartLoginRegisterContinue);