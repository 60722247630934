// jQuery free
import {onDOMReady} from './dom';

export const ELEMENT_NAME = 'kvik-vehicle-license-plate';

export const ATTRIBUTE_DATA_LICENSE_PLATE = 'data-license-plate';

class VehicleLicensePlateElement extends HTMLElement {
    static observedAttributes = [ATTRIBUTE_DATA_LICENSE_PLATE];

    constructor() {
        super();
    }

    connectedCallback() {
        this.getElementsByClassName('vehicleLicensePlate__numberPart')[0].textContent = this.getAttribute(ATTRIBUTE_DATA_LICENSE_PLATE);
    }

    attributeChangedCallback(name, oldValue, newValue) {
        this.getElementsByClassName('vehicleLicensePlate__numberPart')[0].textContent = newValue;
    }
}

onDOMReady(() => {
    // todo: Replace with requestIdleCallback() when the children are added here, not in latte.
    window.customElements.define(ELEMENT_NAME, VehicleLicensePlateElement);
});