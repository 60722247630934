// jQuery free
import {onDOMReady} from './dom';


onDOMReady(() => {
    const vehicleModelsHeadingElements = document.getElementsByClassName('vehicleModels__heading');

    for (const vehicleModelsHeadingElement of vehicleModelsHeadingElements) {
        vehicleModelsHeadingElement.addEventListener('click', function () {
            const section = vehicleModelsHeadingElement.closest('.vehicleModels__section');

            if (section.classList.contains('vehicleModels__section--open')) {
                section.classList.remove('vehicleModels__section--open');
            } else {
                section.classList.add('vehicleModels__section--open');
            }
        });
    }
});