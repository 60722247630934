// jQuery free
import {onDOMReady} from './dom';

const FormClassName = 'categorySelection__form';
const StepSelectClassName = 'categorySelection__categoryBrand';
const SubmitClassName = 'categorySelection__formSubmit';
const SpinnerClassName = 'categorySelection__spinner';
const HiddenSpinnerClassName = 'categorySelection__spinner--hidden';

const CategoryChildrenAPIEndpoint = '/api/1/category/children';

onDOMReady(() => {
    const forms = document.getElementsByClassName(FormClassName);

    for (const form of forms) {
        const submit = form.getElementsByClassName(SubmitClassName)[0];
        const stepSelects = form.getElementsByClassName(StepSelectClassName);
        const placeholders = form.getElementsByTagName('i');
        const spinners = form.getElementsByClassName(SpinnerClassName);

        for (const spinner of spinners) {
            spinner.innerHTML = km_spinner.html;
        }

        const allowToNavigate = (url, stepSelectsIndex) => {
            form.action = url;
            placeholders[stepSelectsIndex].hidden = true;
            submit.firstElementChild.disabled = false;
        };

        const disableAll = () => {
            for (let i = 0; i < stepSelects.length; i++) {
                stepSelects[i].disabled = true;
                placeholders[i].hidden = true;
            }
        };

        const invalidateFurther = (stepSelectsIndex, fromNext = true) => {
            placeholders[stepSelectsIndex].hidden = false;
            const fromIndex = fromNext === true ? (stepSelectsIndex + 1) : stepSelectsIndex;

            for (let i = fromIndex; i < stepSelects.length; i++) {
                stepSelects[i].innerHTML = '';
                stepSelects[i].disabled = true;
                stepSelects[i].value = '';
                placeholders[i].hidden = true;
            }
        };

        const enablePrevious = (stepSelectsIndex) => {
            placeholders[stepSelectsIndex].hidden = false;

            for (let i = stepSelectsIndex; i >= 0; i--) {
                stepSelects[i].disabled = false;
            }
        };

        const fetchChildren = (stepSelectsIndex = 0, value, url) => {
            const categoryId = stepSelectsIndex === 0 ? form.dataset.categoryId : value;

            disableAll();
            submit.firstElementChild.disabled = true;
            spinners[stepSelectsIndex].classList.toggle(HiddenSpinnerClassName, false);
            invalidateFurther(stepSelectsIndex, false);
            fetch(CategoryChildrenAPIEndpoint + `?id=${parseInt(categoryId)}`, {
                method: 'GET',
                mode: 'same-origin', // send cookies _nss for isSameSite()
                headers: {
                    'X-Requested-With': 'XMLHttpRequest' // To force Nette isAjax() to be true.
                }
            }).then((response) => {
                response.json().then((data) => {
                    if (data.length === 0) {
                        if (stepSelectsIndex !== 0) {
                            invalidateFurther(stepSelectsIndex);
                            allowToNavigate(url, stepSelectsIndex);
                        }
                    } else {
                        [{id: '', name: '', url: ''}, ...data].forEach((category) => {
                            const {id, name, url} = category;

                            const option = document.createElement('option');
                            option.value = id;
                            option.innerText = name;
                            option.dataset.url = url;

                            stepSelects[stepSelectsIndex].appendChild(option);
                        });

                        stepSelects[stepSelectsIndex].onchange = (event) => {
                            const {value, selectedOptions} = event.target;
                            const url = selectedOptions[0].dataset.url;

                            submit.firstElementChild.disabled = true;

                            if (!value) {
                                invalidateFurther(stepSelectsIndex);

                                return;
                            }

                            if ((stepSelectsIndex + 1) === stepSelects.length) {
                                allowToNavigate(url, stepSelectsIndex);

                                return;
                            }

                            fetchChildren(stepSelectsIndex + 1, value, url);
                        };
                    }

                    enablePrevious(stepSelectsIndex);
                    spinners[stepSelectsIndex].classList.toggle(HiddenSpinnerClassName, true);
                });
            });
        };

        fetchChildren();
    }
});