import {onDOMReady} from './dom';

const DATA_LOCALE_LANGUAGE_CODE = 'data-locale-language-code';
const DATA_LOCALE_CURRENCY_CODE = 'data-locale-currency-code';
const DATA_LOCALE_COUNTRY_CODE = 'data-locale-country-code';

onDOMReady(() => {
    const elements = document.querySelectorAll(`[${DATA_LOCALE_LANGUAGE_CODE}], [${DATA_LOCALE_CURRENCY_CODE}]`);

    elements.forEach(element => {
        element.addEventListener('click', handleEvent);
        element.addEventListener('tap', handleEvent);
    });

    function handleEvent(event) {
        event.preventDefault();

        fetch('/api/1/i18n/locale', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                currencyCode: event.currentTarget.getAttribute(DATA_LOCALE_CURRENCY_CODE),
                languageCode: event.currentTarget.getAttribute(DATA_LOCALE_LANGUAGE_CODE),
                countryCode: event.currentTarget.getAttribute(DATA_LOCALE_COUNTRY_CODE),
                currentUrl: window.location.href
            })
        }).then(response => response.json()).then(data => {
            if (data.url) {
                window.location.replace(data.url);
            } else {
                window.location.reload();
            }
        });
    }
});
