// jQuery free
import {onDOMReady} from './dom';
import StickySidebar from 'sticky-sidebar/src/sticky-sidebar';
import ResizeSensor from 'resize-sensor/ResizeSensor';

onDOMReady(function () {
    let sidebarLayoutSidebar = document.getElementsByClassName('sidebarLayout__sidebar');

    if (!sidebarLayoutSidebar.length) {
        return;
    }

    if (!sidebarLayoutSidebar[0].getAttribute('data-is-sticky')) {
        return;
    }

    const sidebarSticky = document.getElementsByClassName('sidebarLayout__sidebar__sticky')[0];

    if (sidebarSticky.offsetHeight > document.getElementsByClassName('sidebarLayout__main')[0].offsetHeight) {
        return;
    }

    let stickySidebar = new StickySidebar('.sidebarLayout__sidebar', {
        innerWrapperSelector: '.sidebarLayout__sidebar__sticky',
        containerSelector: '.sidebarLayout',
        topSpacing: 30,
        bottomSpacing: 8,
        resizeSensor: true,
        minWidth: 1200
    });

    new ResizeSensor(sidebarSticky, () => {
        stickySidebar.updateSticky();
    });
});
