// jQuery free
import {onDOMReady} from './dom';

const LOCAL_STORAGE_KEY = 'km_searchHistory';

const TYPE_PHRASE = 'phrase';
const TYPE_PRODUCT = 'product';
const TYPE_VEHICLE = 'vehicle';
const TYPE_EXAMPLE = 'example';

const TYPES = [
    TYPE_PHRASE,
    TYPE_PRODUCT,
    TYPE_VEHICLE
];

const MAX_ITEMS = 8;

const ID_CLEAR_BUTTON = 'searchHistory__clearButton';
const ID_ITEM_TEMPLATE = 'searchHistory__itemTemplate';

const CLASS_NAME_ITEMS = 'searchHistory__items';
const CLASS_NAME_ITEM_TITLE = 'searchHistory__item__title';

const domElementById = [];

const getDomElementById = (id) => {
    if (!domElementById.hasOwnProperty(id)) {
        domElementById[id] = document.getElementById(id);
    }

    return domElementById[id];
};

const getSectionIdByType = (type) => {
    return 'searchHistory__' + type;
};


const recoverSearchHistory = (searchHistory) => {
    const newSearchHistory = {};

    for (const type of TYPES) {
        if (searchHistory && searchHistory.hasOwnProperty(type) && Array.isArray(searchHistory[type])) {
            newSearchHistory[type] = searchHistory[type];
        } else {
            newSearchHistory[type] = [];
        }
    }

    return newSearchHistory;
};

const loadSearchHistoryFromLocalStorage = () => {
    try {
        const serializedState = localStorage.getItem(LOCAL_STORAGE_KEY);
        if (serializedState !== null) {
            return recoverSearchHistory(JSON.parse(serializedState));
        }
    } catch (error) {
        console.error(error);
    }

    return recoverSearchHistory({});
};

const saveSearchHistoryToLocalStorage = (searchHistory) => {
    try {
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(searchHistory));
    } catch (error) {
        console.error(error);
    }
};

const sliceSearchHistorySection = (historySection, maxLimit) => {
    if (historySection.length >= maxLimit) {
        return historySection.slice(0, maxLimit - 1);
    }
    return historySection;
};

const addItemToSearchHistory = (item, searchHistory) => {
    searchHistory[item.type] = searchHistory[item.type].filter((searchHistoryItem) => {
        return (searchHistoryItem.id !== item.id);
    });

    searchHistory[item.type] = sliceSearchHistorySection(searchHistory[item.type], MAX_ITEMS);
    searchHistory[item.type].unshift(item);

    return searchHistory;
};

const createItem = (type, id, title, props = {}) => {
    return {
        ...props,
        type: type,
        timestamp: Math.floor(new Date().getTime() / 1000),
        id: String(id).replace(/\W/g, '').toLowerCase(),
        title: title,
    };
};

const addItemsToSearchHistoryFromKMPayload = (searchHistory) => {
    const {KMPayload} = window;

    if (KMPayload !== undefined && KMPayload !== null) {
        if (KMPayload.hasOwnProperty('searchPhrase')) {
            const {searchPhrase} = KMPayload;
            const item = createItem(TYPE_PHRASE, searchPhrase, searchPhrase, {});
            searchHistory = addItemToSearchHistory(item, searchHistory);
        }


        if (KMPayload.hasOwnProperty('productId') === true) {
            const {productId, productName, productUrl, productMainImageUrl} = KMPayload;
            const item = createItem(TYPE_PRODUCT, productId, productName, {
                url: productUrl,
                imageUrl: productMainImageUrl
            });
            searchHistory = addItemToSearchHistory(item, searchHistory);
        }


        if (KMPayload.hasOwnProperty('tecdocCarId') === true) {
            const {tecdocCarId, tecdocCarManufacturer, tecdocCarManufacturerSlug, tecdocCarTitle, tecdocCarModel, tecdocCarMotorization, tecdocCarUrl} = KMPayload;
            const item = createItem(TYPE_VEHICLE, tecdocCarId, tecdocCarTitle, {
                url: tecdocCarUrl,
                manufacturer: tecdocCarManufacturer,
                manufacturerSlug: tecdocCarManufacturerSlug,
                model: tecdocCarModel,
                motorization: tecdocCarMotorization,
            });
            searchHistory = addItemToSearchHistory(item, searchHistory);
        }
    }

    return searchHistory;
};

const renderSection = (searchHistory, type) => {
    const domSection = getDomElementById(getSectionIdByType(type));
    const domItems = domSection.getElementsByClassName(CLASS_NAME_ITEMS)[0];

    domItems.textContent = '';

    for (const searchHistoryItem of searchHistory[type]) {
        domItems.appendChild(renderItem(searchHistoryItem, domSection));
    }

    domSection.style.display = searchHistory[type].length === 0 ? 'none' : 'block';
};

const renderItem = (searchHistoryItem, domSection) => {
    const domTemplateContent = getDomElementById(ID_ITEM_TEMPLATE).content;
    const domItem = document.importNode(domTemplateContent, true).children[0];

    domItem.className += ' --' + searchHistoryItem.type;

    // a[href]
    if (searchHistoryItem.url) {
        domItem.href = searchHistoryItem.url;
    } else {
        domItem.href = domSection.getAttribute('data-href').replace('__PLACEHOLDER__', encodeURIComponent(searchHistoryItem.title));
    }

    // itemTitle
    const domItemTitle = domItem.getElementsByClassName(CLASS_NAME_ITEM_TITLE)[0];
    domItemTitle.innerText = searchHistoryItem.title;

    // img
    const domItemImg = domItem.getElementsByTagName('img')[0];
    if (searchHistoryItem.imageUrl) {
        domItemImg.src = searchHistoryItem.imageUrl;
    } else {
        domItemImg.parentNode.removeChild(domItemImg);
    }

    return domItem;
};

const renderExample = (searchHistory) => {
    const sectionId = getSectionIdByType(TYPE_EXAMPLE);
    const exampleTitles = getDomElementById(sectionId).getAttribute('data-examples').split(/[;,|]/);

    const idsToSkip = searchHistory[TYPE_PHRASE].map((searchHistoryItem) => {
        return searchHistoryItem.id;
    });

    const items = {};

    items[TYPE_EXAMPLE] = exampleTitles.map((exampleTitle) => {
        return createItem(TYPE_EXAMPLE, exampleTitle, exampleTitle);
    }).filter((item) => {
        return !idsToSkip.includes(item.id);
    });

    renderSection(items, TYPE_EXAMPLE);

    getDomElementById(sectionId).style.display = items[TYPE_EXAMPLE].length > 0 && searchHistory[TYPE_PHRASE].length < 3 ? 'block' : 'none';
};

const renderClear = (searchHistory) => {
    let totalCount = 0;

    for (const type of [TYPE_PHRASE, TYPE_PRODUCT]) {
        totalCount += searchHistory[type].length;
    }

    getDomElementById(getSectionIdByType('clear')).style.display = totalCount === 0 ? 'none' : 'block';
};


const renderSearchHistory = (searchHistory) => {
    renderSection(searchHistory, TYPE_PHRASE);
    renderSection(searchHistory, TYPE_PRODUCT);
    renderExample(searchHistory);
    renderClear(searchHistory);
};


const clearSearchHistory = () => {
    const searchHistory = recoverSearchHistory({});
    saveSearchHistoryToLocalStorage(searchHistory);
    renderSearchHistory(searchHistory);
};


const registerClearSearchHistoryEventListener = () => {
    getDomElementById(ID_CLEAR_BUTTON)?.addEventListener('click', (event) => {
        event.preventDefault();
        clearSearchHistory();
    });
};

onDOMReady(() => {
    let searchHistory = loadSearchHistoryFromLocalStorage();
    searchHistory = addItemsToSearchHistoryFromKMPayload(searchHistory);
    saveSearchHistoryToLocalStorage(searchHistory);
    registerClearSearchHistoryEventListener();
    renderSearchHistory(searchHistory);
});



// append vehicle history items to DOM
// const vehicleHistoryItemTemplate = getDomElementById("vehicleHistory__itemTemplate");
// if (vehicleHistoryItemTemplate !== null) {
//     const vehicleHistory = document.getElementsByClassName("vehicleHistory")[0];
//     for (const vehicle of searchHistoryData.vehicles) {
//         const vehicleHistoryItem = vehicleHistoryItemTemplate.content.cloneNode(true);
//
//         const vehicleHistoryItemAnchor = vehicleHistoryItem.children[0];
//         vehicleHistoryItemAnchor.href = vehicle.url;
//
//         const vehicleHistoryItemImage = vehicleHistoryItemAnchor.children[0];
//         vehicleHistoryItemImage.src = vehicleHistoryItemImage.getAttribute('data-src-base') + '/' + vehicle.manufacturerSlug + '.png';
//         vehicleHistoryItemImage.alt = vehicle.manufacturer;
//
//         vehicleHistoryItemAnchor.children[1].innerText = vehicle.manufacturer + ' ' + vehicle.model;
//         vehicleHistoryItemAnchor.children[2].innerText = vehicle.motorization;
//
//         vehicleHistory.appendChild(vehicleHistoryItem);
//     }
//     if (searchHistoryData.vehicles.length !== 0) {
//         vehicleHistory.className += ' ' + vehicleHistory.className + FILLLED_CLASS_NAME_SUFFIX;
//     }
// }