import Swiper from 'swiper';
import {Autoplay, Mousewheel, Navigation, Pagination, Scrollbar} from 'swiper/modules';
import {onDOMReady} from './dom';
import layoutUnitsCalculate from './layoutUnits';

const CLASS_NAME_SWIPER_BUTTON_NEXT = 'swiper-button-next';
const CLASS_NAME_SWIPER_BUTTON_PREV = 'swiper-button-prev';
const CSS_PROPERTY_GRID_NAV_GAP = '--sf-grid-nav-gap';

function recalculateSwiperButtonsTopPosition(swiperElement) {
    const firstImgElement = swiperElement.getElementsByTagName('img')[0];
    const buttonNextElement = swiperElement.getElementsByClassName(CLASS_NAME_SWIPER_BUTTON_NEXT)[0];
    const buttonPrevElement = swiperElement.getElementsByClassName(CLASS_NAME_SWIPER_BUTTON_PREV)[0];
    if (firstImgElement && buttonNextElement && buttonPrevElement) {
        const computedTop = Math.round(firstImgElement.offsetTop + firstImgElement.height / 2);
        const buttonClientRects = buttonNextElement.getClientRects();
        const maxTop = buttonClientRects.length ? Math.round(buttonNextElement.getClientRects()[0].height / 2) : NaN;
        const finalTop = isNaN(maxTop) ? computedTop : Math.max(computedTop, maxTop);
        buttonNextElement.style.top = finalTop + 'px';
        buttonPrevElement.style.top = finalTop + 'px';
    }
}

function recalculateSlidesPerView(swiperElement) {
    if (swiperElement.dataset.type === 'imageGallery') {
        swiperElement.swiper.params.slidesPerView = getComputedStyle(document.documentElement).getPropertyValue('--layout-main-column-count').trim();
        swiperElement.swiper.update();
    }
}

onDOMReady(() => {
    layoutUnitsCalculate();
    const swiperElements = document.getElementsByClassName('swiper');

    if (!swiperElements.length) {
        return;
    }

    const spaceBetween = parseInt(window.getComputedStyle(document.documentElement).getPropertyValue(CSS_PROPERTY_GRID_NAV_GAP).replace(/[^0-9]/i, '')) ?? 0;
    const layoutMainColumnCount = getComputedStyle(document.documentElement).getPropertyValue('--layout-main-column-count').trim();

    for (const swiperElement of swiperElements) {
        const swiperClassModifier = '.' + swiperElement.classList[1]; // Classname with codename to separate configs by data attributes

        const swiperOptions = {
            modules: [Navigation, Autoplay, Pagination, Scrollbar, Mousewheel],
            slidesPerView: 'auto',
            spaceBetween: spaceBetween,
            centeredSlides: swiperElement.dataset.align === 'center',
            centeredSlidesBounds: true,
            rewind: false,
            preventClicks: true,
            watchSlidesProgress: true,
            direction: 'horizontal',
            mousewheel: {
                forceToAxis: true
            },
            navigation: {
                nextEl: `${swiperClassModifier} > .${CLASS_NAME_SWIPER_BUTTON_NEXT}`,
                prevEl: `${swiperClassModifier} > .${CLASS_NAME_SWIPER_BUTTON_PREV}`,
            },
        };

        if (swiperElement.getElementsByClassName('swiper-pagination')[0]) {
            swiperOptions.pagination = {
                el: `${swiperClassModifier} > .swiper-pagination`,
                clickable: true,
            };
        }

        if (swiperElement.getElementsByClassName('swiper-scrollbar')[0]) {
            swiperOptions.scrollbar = {
                el: `${swiperClassModifier} > .swiper-scrollbar`,
                hide: false,
            };
        }

        if (swiperElement.dataset.type === 'imageSlider') {
            swiperOptions.slidesPerView = 1;
            swiperOptions.spaceBetween = 0;
        }

        if (swiperElement.dataset.type === 'iconImageSlider') {
            swiperOptions.slidesPerView = 'auto';
            swiperOptions.spaceBetween = spaceBetween;
        }

        if (swiperElement.dataset.type === 'imageGallery') {
            swiperOptions.slidesPerView = layoutMainColumnCount;
            swiperOptions.spaceBetween = 8;
        }

        if (swiperElement.dataset.autoplay) {
            swiperOptions.autoplay = {
                delay: 4000,
                disableOnInteraction: true,

            };
        }

        new Swiper(swiperClassModifier, swiperOptions);

        recalculateSwiperButtonsTopPosition(swiperElement);
        const resizeObserver = new ResizeObserver(
            (entries) => {
                for (const entry of entries) {
                    recalculateSwiperButtonsTopPosition(entry.target);
                    recalculateSlidesPerView(entry.target);
                }
            }
        );
        resizeObserver.observe(swiperElement);
    }
});