// jQuery free
import {onDOMReady} from './dom';

const CLASS_FILTER_LIST_ITEM = 'filter__listItem';
const CLASS_FILTER_LIST_SHOW_MORE_BTN = 'filter__list__showMoreBtn';
const ATTR_HIDDEN = 'hidden';


const
    DATA_FILTERS_INIT = 'data-filters-init',
    HIDDEN_CLASSNAME_MODIFIER = '--hidden';


function initFilterListShowMore(buttonElement) {
    if (buttonElement.hasAttribute(ATTR_HIDDEN)) {
        return;
    }

    buttonElement.addEventListener('click', () => {
        for (const itemElement of buttonElement.parentElement.getElementsByClassName(CLASS_FILTER_LIST_ITEM)) {
            itemElement.removeAttribute(ATTR_HIDDEN);
        }
        buttonElement.setAttribute(ATTR_HIDDEN, '1');
    });
}

const initShowAllButton = () => {
    const showAllButtons = document.querySelectorAll('.filter__listItem.--showAllButton');
    showAllButtons.forEach(function (showAllButton) {
        if (showAllButton.hasAttribute(DATA_FILTERS_INIT)) {
            return;
        }

        showAllButton.setAttribute(DATA_FILTERS_INIT, '1');
        showAllButton.addEventListener('click', function () {
            const hiddenItems = showAllButton.parentElement.querySelectorAll('.filter__listItem.--hidden');
            showAllButton.remove();
            hiddenItems.forEach(function (element) {
                element.classList.remove(HIDDEN_CLASSNAME_MODIFIER);
            });
        });
    });
};


onDOMReady(function () {
    for (const buttonElement of document.getElementsByClassName(CLASS_FILTER_LIST_SHOW_MORE_BTN)) {
        initFilterListShowMore(buttonElement);
    }

    initShowAllButton();
    document.addEventListener('km_modal_show_html', function (event) {
        initShowAllButton();
    });
});