// jQuery free
import {onHtmlLayoutReady, requestIdleCallback} from './dom';
import {getCart} from './payload';

const CART_BUTTON_CELL_QUERY_SELECTOR = '.layoutHeaderMainBar__buttonCell.--shoppingCart';
const CART_BUTTON_CLASS = 'layoutHeaderMainBar__button';
const CART_BUTTON_LABEL_CLASS = 'layoutHeaderMainBar__button__label';
const HIGHLIGHTED_CLASS = '--highlighted';

function formatMoney(number, currencyCode, localeTag = null) {
    localeTag = localeTag ?? document.documentElement.lang;

    const numberFomratter = new Intl.NumberFormat(localeTag, {
        style: 'currency',
        currency: currencyCode,
        minimumFractionDigits: 0
    });

    return numberFomratter.format(number,);
}

const render = () => {
    const cartData = getCart();
    if (!cartData) {
        return;
    }

    const buttonCellElement = document.querySelector(CART_BUTTON_CELL_QUERY_SELECTOR);
    if (!buttonCellElement) {
        return;
    }

    const buttonElement = buttonCellElement.getElementsByClassName(CART_BUTTON_CLASS)[0];
    const buttonLabelElement = buttonCellElement.getElementsByClassName(CART_BUTTON_LABEL_CLASS)[0];

    if (cartData.count) {
        const formattedMoney = formatMoney(cartData.totalPrice.amountWithVat, cartData.totalPrice.currencyCode);
        buttonLabelElement.textContent = `${cartData.count} ${buttonCellElement.dataset.labelPieces} / ${formattedMoney}`;
        buttonElement.classList.add(HIGHLIGHTED_CLASS);
    } else {
        buttonLabelElement.textContent = buttonCellElement.dataset.label;
        buttonElement.classList.remove(HIGHLIGHTED_CLASS);
    }
};

document.addEventListener(window.kvik_ajax.EVENT_KVIK_AJAX_PAYLOAD, function () {
    requestIdleCallback(render);
});

onHtmlLayoutReady(render);