// jQuery free
import {onDOMReady} from './dom';

onDOMReady(function () {
    const showMoreButtons = document.getElementsByClassName('productOeNumbers__showMoreBtn');

    for (const showMoreButton of showMoreButtons) {
        showMoreButton.addEventListener('click', function (event) {
            event.preventDefault();

            for (const item of showMoreButton.parentElement.parentElement.children) {
                item.hidden = false;
            }

            showMoreButton.parentElement.hidden = true;
        });
    }
});