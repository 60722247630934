import {onDOMReady} from './dom';

const CSS_PROPERTY_SF_LAYOUT_DEFAULT_COLUMN_WIDTH = '--ksfdef-layout-default-column-width';

let mainElement;
let containerLayoutElement;
const mainDefaultWidth = 1500;

const layoutUnitsCalculate = () => {
    if (!mainElement) {
        mainElement = document.getElementsByTagName('main')[0];
        if (!mainElement && !containerLayoutElement) {
            containerLayoutElement = document.getElementsByClassName('containerLayout')[0];
        }
    }

    const defaultColumnWidth = parseInt(window.getComputedStyle(document.documentElement).getPropertyValue(CSS_PROPERTY_SF_LAYOUT_DEFAULT_COLUMN_WIDTH).replace(/[^0-9]/i, ''));
    const style = document.documentElement.style;
    const mainWidth = (mainElement ?? containerLayoutElement)?.offsetWidth ?? mainDefaultWidth;
    const mainColumnCount = Math.floor(mainWidth / defaultColumnWidth) || 1;
    const mainColumnCountLg = Math.round(mainColumnCount / 1.5) || 1;
    const mainColumnCountXlg = Math.round(mainColumnCount / 2) || 1;
    const mainColumnWidth = mainWidth / mainColumnCount;
    const mainColumnWidthLg = mainWidth / mainColumnCountLg;
    const mainColumnWidthXlg = mainWidth / mainColumnCountXlg;

    style.setProperty('--layout-main-width', `${mainWidth}px`);
    style.setProperty('--layout-main-column-count', `${mainColumnCount}`);
    style.setProperty('--layout-main-column-count-lg', `${mainColumnCountLg}`);
    style.setProperty('--layout-main-column-count-xlg', `${mainColumnCountXlg}`);
    style.setProperty('--layout-main-column-width', `${mainColumnWidth}px`);
    style.setProperty('--layout-main-column-width-lg', `${mainColumnWidthLg}px`);
    style.setProperty('--layout-main-column-width-xlg', `${mainColumnWidthXlg}px`);
};

window.addEventListener('resize', layoutUnitsCalculate);
onDOMReady(() => layoutUnitsCalculate);
layoutUnitsCalculate();

export default layoutUnitsCalculate;