/**
 * @typedef {Object} Vehicle
 * @property {int} carId
 * @property {string} vehicleBrandName
 * @property {string} vehicleModelName
 * @property {string} vehicleModelNameWithoutCodes
 * @property {string} name
 * @property {string} powerRange
 * @property {string} yearsRange
 * @property {array} motorCodes
 * @property {Object} mainImage
 * @property {?string} vehicleBrandLogoUrl
 */

/**
 * @returns {?Vehicle}
 */
export function getSelectedVehicle() {
    return window.KMPayload.selectedVehicle ?? null;
}

/**
 * @typedef {Object} VehicleSearchResultDetail
 * @property {?string} vin
 * @property {?string} vinSlug
 * @property {?string} licensePlate
 * @property {?string} vehicleName
 * @property {?string} engine
 */

/**
 * @returns {?VehicleSearchResultDetail}
 */
export function getSelectedVehicleSearchResultDetail() {
    return window.KMPayload.selectedVehicleSearchResultDetail ?? null;
}

/**
 * @returns {?string}
 */
export function getSelectedVehicleType() {
    return window.KMPayload.selectedVehicleType ?? null;
}

/**
 * @typedef {Object} Cart
 * @property {Object} price
 * @property {int} count
 */

/**
 * @returns {?Cart}
 */
export function getCart() {
    return window.KMPayload.cart ?? null;
}