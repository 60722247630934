// jQuery free
import {onDOMReady} from './dom';

onDOMReady(function () {
    if (!window.sessionStorage) {
        return;
    }

    const inputEmail = document.getElementById('shoppingCartLoginOrRegister-registration-email');
    const inputDpaEmail = document.getElementById('frm-deliveryPaymentAddress-addressForm-billing-email');
    const inputRegistrationEmail = document.getElementById('frm-registrationForm-registrationForm-email');

    const refreshInputs = function (force) {
        const email = sessionStorage.getItem('shoppingCartRegistrationEmail');
        const inputs = [inputEmail, inputDpaEmail, inputRegistrationEmail];
        inputs.forEach(function (input) {
            if (input && (force || !input.value)) {
                input.value = email;
            }
        });
    };

    refreshInputs(false);

    const formButtons = document.getElementsByClassName('shoppingCartLoginOrRegister__formButtons btn');

    if (!formButtons.length) {
        return;
    }

    formButtons.forEach(function (button) {
        button.addEventListener('click', function (event) {
            sessionStorage.setItem('shoppingCartRegistrationEmail', inputEmail.value);
            refreshInputs(true);
        });
    });
});
