import {onDOMReady} from './dom';

onDOMReady(() => {
    const iconImageSliderPageParts = document.getElementsByClassName('pagePart--imageSlider');

    if (!iconImageSliderPageParts.length) {
        return;
    }

    for (const pagePart of iconImageSliderPageParts) {
        let isIconImageSliderPagePart = false;
        const pictures = pagePart.getElementsByClassName('imageSliderPagePart__picture');

        if (!pictures.length) {
            continue;
        }

        for (const picture of pictures) {
            if (!isIconImageSliderPagePart && picture.getAttribute('data-height') <= 100) {
                isIconImageSliderPagePart = true;
            }
        }

        if (isIconImageSliderPagePart) {
            pagePart.classList.add('pagePart--iconImageSlider');
        }
    }
});
