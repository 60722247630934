// jQuery free
import {onDOMReady} from './dom';

const DATA_SHOW_HIDDEN_IN_TARGET = 'data-showhiddenintarget';


function initTriggerElement(triggerElement) {
    if (triggerElement.hidden) {
        return;
    }

    triggerElement.addEventListener('click', (event) => {
        const targetElement = document.getElementById(triggerElement.getAttribute(DATA_SHOW_HIDDEN_IN_TARGET));
        if (targetElement) {
            for (const hiddenElement of targetElement.querySelectorAll(`:scope > [hidden]`)) {
                hiddenElement.hidden = false;
            }
        }
        triggerElement.hidden = true;
        event.preventDefault();
    });
}


onDOMReady(function () {
    for (const triggerElement of document.querySelectorAll(`[${DATA_SHOW_HIDDEN_IN_TARGET}]`)) {
        initTriggerElement(triggerElement);
    }
});