// jQuery free
import {effectShakeElement} from './effects';
import {onDOMReady} from './dom';

const CLASS_VEHICLE_SEARCH_FORM = 'vehicleSearch__form';
const CLASS_VEHICLE_SEARCH_INPUT = 'vehicleSearch__input';
const CLASS_VEHICLE_SEARCH_CHAR = 'vehicleSearch__char';
const CLASS_VEHICLE_SEARCH_CHAR_COUNTER = 'vehicleSearch__charCounter';
const CLASS_VEHICLE_SEARCH_CARRET = 'vehicleSearch__carret';
const CLASS_ACTIVE = '--active';
const CLASS_OK = '--ok';



onDOMReady(() => {
    const forms = document.getElementsByClassName(CLASS_VEHICLE_SEARCH_FORM);

    for (const form of forms) {
        /** @type {HTMLFormElement} form */

        const input = form.getElementsByClassName(CLASS_VEHICLE_SEARCH_INPUT)[0];
        const chars = form.getElementsByClassName(CLASS_VEHICLE_SEARCH_CHAR);
        const charCounter = form.parentNode.getElementsByClassName(CLASS_VEHICLE_SEARCH_CHAR_COUNTER)[0];
        const length = parseInt(input.getAttribute('minlength'));

        const carret = document.createElement('span');
        carret.classList.add(CLASS_VEHICLE_SEARCH_CARRET);


        const sanitizeValue = () => {
            const originalValue = input.value.toUpperCase();
            input.value = originalValue.replace(/[^0-9A-Z]/gi, '').substring(0, length);

            return originalValue !== input.value;
        };

        const refreshChars = () => {
            let carretAdded = false;

            for (let i = 0; i < chars.length; i++) {
                const char = input.value.charAt(i);
                chars[i].innerHTML = input.value.charAt(i);
                if ((char === '' && carretAdded === false) || (input.value.length === length && i === (length - 1))) {
                    chars[i].appendChild(carret);
                    carretAdded = true;
                }
            }

            charCounter.innerHTML = `${input.value.length}/${length}`;
            if (input.value.length === length) {
                form.classList.add(CLASS_OK);
            } else {
                form.classList.remove(CLASS_OK);
            }
        };

        const disabledHandler = () => {
            if (input.hasAttribute('disabled')) {
                window.kvik_ajax.sendAjaxRequestFromElement(input);
                return true;
            }

            return false;
        };

        const formSubmitHandler = (event) => {
            if (sanitizeValue() || input.value.length !== length) {
                effectShakeElement(form);
                event.preventDefault();
            }
        };

        const inputChangeHandler = () => {
            if (sanitizeValue()) {
                effectShakeElement(form);
            }
            refreshChars();
        };


        if (input) {
            const isDisabled = input.hasAttribute('disabled');
            inputChangeHandler();
            form.addEventListener('submit', () => disabledHandler() || formSubmitHandler());
            form.addEventListener('click', () => disabledHandler() || inputChangeHandler());
            input.addEventListener('input', () => disabledHandler() || inputChangeHandler());
            input.addEventListener('change', () => disabledHandler() || inputChangeHandler());
            input.addEventListener('focus', () => disabledHandler() || (() => {
                form.classList.add(CLASS_ACTIVE);
                charCounter.classList.add(CLASS_ACTIVE);
            })());
            input.addEventListener('blur', () => disabledHandler() || (() => {
                form.classList.remove(CLASS_ACTIVE);
                charCounter.classList.remove(CLASS_ACTIVE);
            })());
        }
    }
});