// jQuery free
import {getVehicleSelectionVehicleBrands, getVehicleSelectionVehicleModels, getVehicleSelectionVehicleMotorizations} from './api';
import {onDOMReady} from './dom';
import {getSelectedVehicle, getSelectedVehicleType} from './payload';


const CLASS_VEHICLE_SELECTION_FORM = 'vehicleSelection__form';
const CLASS_VEHICLE_SELECTION_VEHICLE_TYPE = 'vehicleSelection__vehicleType';
const CLASS_VEHICLE_SELECTION_VEHICLE_BRAND = 'vehicleSelection__vehicleBrand';
const CLASS_VEHICLE_SELECTION_VEHICLE_MODEL = 'vehicleSelection__vehicleModel';
const CLASS_VEHICLE_SELECTION_VEHICLE_MOTORIZATION = 'vehicleSelection__vehicleMotorization';
const CLASS_VEHICLE_SELECTION_SPINNER = 'vehicleSelection__spinner';

const CLASS_VEHICLE_SELECTION_MOBILE = 'vehicleSelection__mobile';
const CLASS_VEHICLE_SELECTION_MOBILE_BUTTON = 'vehicleSelection__mobile__button';
const CLASS_VEHICLE_SELECTION_MOBILE_VEHICLE_BRAND_MODEL = 'vehicleSelection__mobile__vehicleBrandModel';
const CLASS_VEHICLE_SELECTION_MOBILE_VEHICLE_NAME = 'vehicleSelection__mobile__vehicleName';
const CLASS_VEHICLE_SELECTION_MOBILE_SELECT_PROMPT = 'vehicleSelection__mobile__selectPrompt';
const CLASS_VEHICLE_SELECTION_MOBILE_IMAGE = 'vehicleSelection__mobile__image';

const CLASS_SELECTED = '--selected';
const DATA_DEFAULT_VEHICLE_TYPE = 'data-default-vehicle-type';
const DATA_SELECT_VEHICLE_HANDLE = 'data-select-vehicle-handle';
const VEHICLE_ID_PLACEHOLDER = '__vehicleId__';
const VEHICLE_MOTORIZATION_STEP = 'vehicleMotorization';


const apiCalls = {
    vehicleBrand: () => {
        return getVehicleSelectionVehicleBrands(state.vehicleType.value);
    },
    vehicleModel: () => {
        return getVehicleSelectionVehicleModels(state.vehicleType.value, state.selectSteps.vehicleBrand.id);
    },
    vehicleMotorization: () => {
        return getVehicleSelectionVehicleMotorizations(state.vehicleType.value, state.selectSteps.vehicleBrand.id, state.selectSteps.vehicleModel.id);
    }
};


const state = {
    formElements: null,
    submitButtonElements: null,
    selectVehicleHandle: null,
    vehicleType: {
        buttonElements: null,
        value: null
    },
    selectSteps: {
        vehicleBrand: {
            selectElements: null,
            spinnerElements: [],
            hintElements: [],
            options: [],
            id: null,
            url: null,
            isLoading: false,
            defaultName: null
        },
        vehicleModel: {
            selectElements: null,
            spinnerElements: [],
            hintElements: [],
            options: [],
            id: null,
            url: null,
            isLoading: false,
            defaultName: null
        },
        vehicleMotorization: {
            selectElements: null,
            spinnerElements: [],
            hintElements: [],
            options: [],
            id: null,
            url: null,
            isLoading: false,
            defaultName: null
        }
    }
};



function initVehicleSelectionForm() {
    state.vehicleType.buttonElements = document.getElementsByClassName(CLASS_VEHICLE_SELECTION_VEHICLE_TYPE);
    state.selectSteps.vehicleBrand.selectElements = document.getElementsByClassName(CLASS_VEHICLE_SELECTION_VEHICLE_BRAND);
    state.selectSteps.vehicleModel.selectElements = document.getElementsByClassName(CLASS_VEHICLE_SELECTION_VEHICLE_MODEL);
    state.selectSteps.vehicleMotorization.selectElements = document.getElementsByClassName(CLASS_VEHICLE_SELECTION_VEHICLE_MOTORIZATION);
    state.submitButtonElements = document.querySelectorAll(`.${CLASS_VEHICLE_SELECTION_FORM} button[type=submit]`);

    const selectedVehicle = getSelectedVehicle();
    const selectedVehicleType = getSelectedVehicleType();
    let isVehicleSelected = false;

    if (selectedVehicle && selectedVehicleType) {
        isVehicleSelected = true;

        state.vehicleType.value = selectedVehicleType;

        state.selectSteps.vehicleBrand.id = selectedVehicle.vehicleBrandId;
        state.selectSteps.vehicleModel.id = selectedVehicle.vehicleModelId;
        state.selectSteps.vehicleMotorization.id = selectedVehicle.id;

        state.selectSteps.vehicleBrand.defaultName = selectedVehicle.vehicleBrandName;
        state.selectSteps.vehicleModel.defaultName = selectedVehicle.vehicleModelName;
        state.selectSteps.vehicleMotorization.defaultName = selectedVehicle.slug;

        state.selectSteps.vehicleMotorization.url = selectedVehicle.url;
    }

    // init vehicleType buttons, retrieve vehicleType.value
    for (const vehicleTypeButtonElement of state.vehicleType.buttonElements) {
        if (vehicleTypeButtonElement.classList.contains(`--${state.vehicleType.value}`)) {
            vehicleTypeButtonElement.classList.add(CLASS_SELECTED);
        }

        vehicleTypeButtonElement.onclick = (event) => {
            event.preventDefault();
            changeVehicleType(vehicleTypeButtonElement.value);
        };
    }

    // init selectSteps, retrieve values
    for (const selectStepName of Object.keys(state.selectSteps)) {
        for (const selectElement of state.selectSteps[selectStepName].selectElements) {
            state.selectSteps[selectStepName].hintElements.push(selectElement.previousElementSibling);
            state.selectSteps[selectStepName].spinnerElements.push(createSpinnerElement(selectElement));

            // if vehicle is selected on backend, load <option>
            if (isVehicleSelected) {
                selectElement.innerHTML += createSelectedOption(state.selectSteps[selectStepName].id, state.selectSteps[selectStepName].defaultName);
            }

            // add onchange handler
            selectElement.onchange = () => {
                const linkHandle = state.selectVehicleHandle;
                const option = parseSelectedOption(selectElement);
                if (linkHandle && selectStepName === VEHICLE_MOTORIZATION_STEP) {
                    window.location.href = linkHandle.replace(VEHICLE_ID_PLACEHOLDER, option.id);
                } else {
                    if (option.id !== state.selectSteps[selectStepName].id) {
                        changeSelect(selectStepName, option.id, option.url);
                    }
                }
            };
        }
    }

    if (getVehicleMotorizationUrl()) {
        // if vehicle is selected on backend, load options for all select steps
        loadSelectStepsOptions(Object.keys(state.selectSteps));
    } else {
        // select first vehicle type if none is selected or use the default vehicle type if there is no switcher for vehicle types
        const vehicleTypeValue = state.vehicleType.buttonElements.length ? state.vehicleType.buttonElements[0].value : state.formElements[0].getAttribute(DATA_DEFAULT_VEHICLE_TYPE);
        changeVehicleType(vehicleTypeValue);
    }
}


function parseSelectedOption(selectElement, parseOptionElements = false) {
    const id = parseInt(selectElement.value) || null;
    const url = selectElement.value.split(';')[1] || null;
    let name = null;

    if (parseOptionElements) {
        for (const optionElement of selectElement.children) {
            if (optionElement.selected) {
                name = optionElement.textContent;
                break;
            }
        }
    }

    return {
        id,
        url,
        name,
        groupName: null
    };
}


function createSpinnerElement(selectElement) {
    const spinnerElement = document.createElement('div');
    spinnerElement.className = CLASS_VEHICLE_SELECTION_SPINNER;
    spinnerElement.innerHTML = km_spinner.html;
    spinnerElement.hidden = true;
    selectElement.parentNode.insertBefore(spinnerElement, selectElement);
    return spinnerElement;
}


function changeVehicleType(vehicleTypeValue) {
    state.vehicleType.value = vehicleTypeValue;
    const selectStepsNames = Object.keys(state.selectSteps);
    clearSelectSteps(selectStepsNames);
    loadSelectStepsOptions([selectStepsNames[0]]);
}


function changeSelect(selectStepName, id, url) {
    state.selectSteps[selectStepName].id = id;
    state.selectSteps[selectStepName].url = url;
    clearSelectSteps(getAllNextSelectStepNames(selectStepName));
    render();
    const nextSelectStepName = getNextSelectStepName(selectStepName);
    if (nextSelectStepName) {
        loadSelectStepsOptions([nextSelectStepName]);
    } else {
        render();
    }
}


function loadSelectStepsOptions(selectStepNames) {
    for (const selectStepName of selectStepNames) {
        state.selectSteps[selectStepName].isLoading = true;
    }
    setTimeout(render, 200);

    const calledApiCalls = selectStepNames.map(selectStepName => {
        return apiCalls[selectStepName].call().then(function (data) {
            state.selectSteps[selectStepName].options = data;
            state.selectSteps[selectStepName].isLoading = false;
        });
    });

    Promise.all(calledApiCalls).then(function () {
        render();
    });
}



function clearSelectSteps(selectStepNames) {
    for (const selectStepName of selectStepNames) {
        state.selectSteps[selectStepName].options = [];
        state.selectSteps[selectStepName].id = null;
        state.selectSteps[selectStepName].url = null;
    }
}


function getAllNextSelectStepNames(selectStepName = '') {
    const selectStepNames = Object.keys(state.selectSteps);
    const index = selectStepNames.indexOf(selectStepName);
    return index === -1 ? [] : selectStepNames.slice(index + 1);
}


function getNextSelectStepName(selectStepName = '') {
    return getAllNextSelectStepNames(selectStepName)[0] || null;
}


function getVehicleMotorizationUrl() {
    return state.selectSteps.vehicleMotorization.url;
}

function getVehicleMotorizationId() {
    return state.selectSteps.vehicleMotorization.id;
}


function render() {
    for (const vehicleTypeButtonElement of state.vehicleType.buttonElements) {
        vehicleTypeButtonElement.disabled = false;
        if (vehicleTypeButtonElement.value === state.vehicleType.value) {
            vehicleTypeButtonElement.classList.add(CLASS_SELECTED);
        } else {
            vehicleTypeButtonElement.classList.remove(CLASS_SELECTED);
        }
    }

    for (const selectStepName of Object.keys(state.selectSteps)) {
        const isDisabled = state.selectSteps[selectStepName].options.length === 0;
        const isLoading = state.selectSteps[selectStepName].isLoading;
        const renderedOptions = renderSelectStepOptions(selectStepName);

        for (const selectElement of state.selectSteps[selectStepName].selectElements) {
            selectElement.innerHTML = renderedOptions;
            selectElement.disabled = isDisabled || isLoading;
        }
        for (const spinnerElement of state.selectSteps[selectStepName].spinnerElements) {
            spinnerElement.hidden = !isLoading;
        }
        for (const hintElement of state.selectSteps[selectStepName].hintElements) {
            hintElement.hidden = isDisabled || isLoading || state.selectSteps[selectStepName].id;
        }
    }

    for (const formElement of state.formElements) {
        formElement.action = getVehicleMotorizationUrl();
    }
    for (const submitButtonElement of state.submitButtonElements) {
        submitButtonElement.disabled = !getVehicleMotorizationUrl();
    }
}


function renderSelectStepOptions(selectStepName) {
    let innerHTML = '<option></option>';
    let lastGroupName = null;

    for (const option of state.selectSteps[selectStepName].options) {
        if (lastGroupName !== option.groupName) {
            if (lastGroupName !== null) {
                innerHTML += `</optgroup>`;
            }
            innerHTML += `<optgroup label="${option.groupName}">`;
            lastGroupName = option.groupName;
        }

        const value = option.id + (option.url ? `;${option.url}` : '');
        const selected = (option.id === state.selectSteps[selectStepName].id ? 'selected' : '');
        innerHTML += `<option value="${value}" ${selected}>${option.name}</option>`;
    }

    if (lastGroupName !== null) {
        innerHTML += `</optgroup>`;
    }

    return innerHTML;
}

function createSelectedOption(id, name) {
    return `<option selected="selected" value="${id}">${name}</option>`;
}


function initVehicleSelectionMobile() {
    const selectedVehicle = getSelectedVehicle();
    if (!selectedVehicle) {
        return;
    }

    const mobileElements = document.getElementsByClassName(CLASS_VEHICLE_SELECTION_MOBILE);

    for (const mobileElement of mobileElements) {
        if (selectedVehicle.imageUrl) {
            const imageElement = mobileElement
                .getElementsByClassName(CLASS_VEHICLE_SELECTION_MOBILE_IMAGE)
                .item(0);
            imageElement.hidden = false;
            imageElement.src = selectedVehicle.imageUrl;
        }

        mobileElement
            .getElementsByClassName(CLASS_VEHICLE_SELECTION_MOBILE_SELECT_PROMPT)
            .item(0).hidden = true;

        mobileElement
            .getElementsByClassName(CLASS_VEHICLE_SELECTION_MOBILE_VEHICLE_NAME)
            .item(0).innerHTML = selectedVehicle.nameWithPowerYears;

        mobileElement
            .getElementsByClassName(CLASS_VEHICLE_SELECTION_MOBILE_VEHICLE_BRAND_MODEL)
            .item(0).innerHTML = selectedVehicle.vehicleBrandName + ' ' + selectedVehicle.vehicleModelNameWithoutCodes;
    }
}


onDOMReady(() => {
    state.formElements = document.getElementsByClassName(CLASS_VEHICLE_SELECTION_FORM);

    if (state.formElements.length === 0) {
        return;
    }

    state.selectVehicleHandle = state.formElements[0].getAttribute(DATA_SELECT_VEHICLE_HANDLE);

    initVehicleSelectionForm();
    initVehicleSelectionMobile();
});