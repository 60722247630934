// jQuery free
import LZString from 'lz-string';

export function getVehicleSelectionVehicleBrands(vehicleType) {
    return sessionStorageCachedAjaxGet('/api/vehicle/vehicle-selection/vehicle-brands', {
        vehicleType
    }, vehicleType);
}


export function getVehicleSelectionVehicleModels(vehicleType, vehicleBrandId) {
    return sessionStorageCachedAjaxGet('/api/vehicle/vehicle-selection/vehicle-models', {
        vehicleType,
        vehicleBrandId
    });
}


export function getVehicleSelectionVehicleMotorizations(vehicleType, vehicleBrandId, vehicleModelId) {
    return sessionStorageCachedAjaxGet('/api/vehicle/vehicle-selection/vehicle-motorizations', {
        vehicleType,
        vehicleBrandId,
        vehicleModelId
    });
}

export function postVehicleSelectionUnselect() {
    return fetch('/api/vehicle/vehicle-selection/vehicle-unselect', {
        method: 'POST',
        mode: 'same-origin', // send cookies _nss for isSameSite()
        headers: {
            'X-Requested-With': 'XMLHttpRequest' // To force Nette isAjax() to be true.
        }
    });
}


function sessionStorageCachedAjaxGet(url, requestData, storageKeySuffix = '') {
    const storageKey = LZString.compressToUTF16('GET' + url + storageKeySuffix);

    const storageValue = sessionStorage.getItem(storageKey);
    if (storageValue) {
        const value = JSON.parse(LZString.decompressFromUTF16(storageValue));
        if (JSON.stringify(value.requestData) === JSON.stringify(requestData)) {
            return Promise.resolve(value.responseData);
        }
        sessionStorage.removeItem(storageKey);
    }

    const queryString = new URLSearchParams(requestData).toString();
    const fetchUrl = url + '?' + queryString;

    return fetch(fetchUrl, {
        method: 'GET'
    })
        .then(response => response.json())
        .then(responseData => {
            const storageValue = LZString.compressToUTF16(JSON.stringify({requestData, responseData}));
            sessionStorage.setItem(storageKey, storageValue);
            return responseData;
        });
}
