// jQuery free
require('intersection-observer');
import LazyLoad from 'vanilla-lazyload';
import {onDOMReady} from './dom';

let lazyLoad;

onDOMReady(() => {
    if ('loading' in HTMLImageElement.prototype) {
        Array.from(document.querySelectorAll('[loading=lazy]')).forEach(function (lazyEl) {
            if (!lazyEl.hasAttribute('src')) {
                lazyEl.className = lazyEl.className ? `${lazyEl.className} loaded` : 'loaded';
                lazyEl.setAttribute('src', lazyEl.getAttribute('data-src'));
            }
        });
    } else {
        lazyLoad = new LazyLoad({
            elements_selector: '[loading=lazy]'
        });
    }
});