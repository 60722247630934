import {onDOMReady} from './dom';
import {createDropdown, DATA_DROPDOWN_ON_HOVER} from './dropdown';

const CLASS_VEHICLE_SCHEMA = 'vehicleSchema';
const CLASS_VEHICLE_SCHEMA_IMAGE = 'vehicleSchema__image';
const ID_VEHICLE_SCHEMA_PART_PREFIX = 'vehicleSchema__part__';
const DATA_INIT = 'data-init';


const initVehicleSchemaElement = function (element) {
    if (element.hasAttribute(DATA_INIT)) {
        return;
    }

    for (const element of document.getElementsByClassName(CLASS_VEHICLE_SCHEMA_IMAGE)) {
        initVehicleSchemaImageElement(element);
    }
};

const initVehicleSchemaImageElement = function (element) {
    const areas = JSON.parse(element.dataset.areas);
    const imgElement = element.getElementsByTagName('img')[0];

    for (let i = 0; i < areas.length; i++) {
        const area = areas[i];
        area.width = area.x2 - area.x1;
        area.height = area.y2 - area.y1;
        area.xCenter = (area.x1 + (area.width) / 2);
        area.yCenter = (area.y1 + (area.height) / 2);
        const dropdownElement = document.getElementById(ID_VEHICLE_SCHEMA_PART_PREFIX + area.code + '__dropdown');
        if (dropdownElement) {
            const areaElement = document.createElement('span');
            const buttonElement = document.createElement('button');
            buttonElement.dataset.code = area.code;
            buttonElement.innerText = area.code;
            buttonElement.setAttribute(DATA_DROPDOWN_ON_HOVER, '1');
            element.appendChild(areaElement);
            areaElement.appendChild(buttonElement);
            createDropdown(buttonElement, dropdownElement);
            areas[i].areaElement = areaElement;
            areas[i].buttonElement = buttonElement;
        }
    }

    areas.sort(function (a, b) {
        return a.xCenter - b.xCenter || a.yCenter - b.yCenter;
    });

    console.log('areas', areas);

    const renderCallback = () => {
        const multiplier = imgElement.offsetWidth / imgElement.naturalWidth;
        for (let i = 0; i < areas.length; i++) {
            const area = areas[i];
            console.log('area', area);
            if (area.areaElement) {
                area.areaElement.style.left = (multiplier * area.x1) + 'px';
                area.areaElement.style.top = (multiplier * area.y1) + 'px';
                area.areaElement.style.height = (multiplier * area.height) + 'px';
                area.areaElement.style.width = (multiplier * area.width) + 'px';
                setTimeout(() => {
                    area.buttonElement.classList.add('--blink');
                }, i * 20);
            }
        }
    };

    imgElement.onload = renderCallback;
    if (imgElement.complete || img.naturalWidth > 0) {
        renderCallback();
    }

    const resizeObserver = new ResizeObserver(() => {
        renderCallback();
    });
    resizeObserver.observe(imgElement.parentElement);
};

onDOMReady(() => {
    for (const element of document.getElementsByClassName(CLASS_VEHICLE_SCHEMA)) {
        initVehicleSchemaElement(element);
    }
});