// jQuery free
import {effectShakeElement} from './effects';
import {onDOMReady} from './dom';

const CLASS_LICENSE_PLATE_SEARCH_FORM = 'licensePlateSearch__form';
const CLASS_LICENSE_PLATE_SEARCH_INPUT = 'licensePlateSearch__input';


onDOMReady(() => {
    const forms = document.getElementsByClassName(CLASS_LICENSE_PLATE_SEARCH_FORM);

    for (const form of forms) {
        /** @type {HTMLFormElement} form */

        const input = form.getElementsByClassName(CLASS_LICENSE_PLATE_SEARCH_INPUT)[0];

        const formSubmitHandler = (event) => {
            if (input.value.trim() === '') {
                effectShakeElement(form);
                event.preventDefault();
            }
        };

        if (input) {
            form.addEventListener('submit', formSubmitHandler);
        }
    }
});