const VEHICLE_ID_PLACEHOLDER = '__VEHICLE_ID_PLACEHOLDER__';
const USER_VEHICLE_ID_PLACEHOLDER = '__USER_VEHICLE_ID_PLACEHOLDER__';
const DATA_INIT = 'init';
const DATA_ID = 'id';

const createUserVehicleItem = ({name, manufacturer, carId, id, model, motorCodes, motorization, powerKw, yearFrom, yearTo, note, vin, licensePlate, vehicleBrandLogoUrl}) => {
    const template = document.querySelector('#userVehicle__itemTemplate');

    if (!template) {
        return;
    }

    const clone = template.content.cloneNode(true);
    const item = clone.querySelector('.userVehicle__item');
    item.dataset[DATA_ID] = id;

    // Generate vehicle URL
    let vehicleUrl = template.dataset.vehicleUrl.replace(VEHICLE_ID_PLACEHOLDER, carId);
    if (id) {
        vehicleUrl = vehicleUrl.replace(USER_VEHICLE_ID_PLACEHOLDER, id);
    }

    // Update name element
    if (name) {
        const nameElement = item.querySelector('.userVehicle__name');
        nameElement.insertAdjacentText('beforeend', name);
    }

    // Update link element
    const linkElement = item.querySelector('.userVehicle__link');
    linkElement.href = vehicleUrl;
    linkElement.innerText = `${manufacturer} ${model}`;

    // Update motor codes element
    if (motorCodes && motorCodes.length) {
        const motorCodesElement = item.querySelector('.userVehicle__motorCodes');
        motorCodesElement.innerText = ` • ${motorCodes.join(', ')}`;
    }

    // Update image element
    const imageElement = item.querySelector('.userVehicle__image');
    imageElement.src = vehicleBrandLogoUrl;
    imageElement.alt = manufacturer;

    // Update motorization element
    if (motorization) {
        const motorizationElement = item.querySelector('.userVehicle__motorization');
        motorizationElement.innerText = motorization;
        motorizationElement.href = vehicleUrl;
    }

    // Update motorization suffix element
    let motorizationSuffix = '';
    if (powerKw) {
        motorizationSuffix += ` • ${powerKw} kW`;
    }
    if (yearFrom || yearTo) {
        motorizationSuffix += ` • ${yearTo ? `${yearFrom} - ${yearTo}` : `${template.dataset.yearFromText} ${yearFrom}`}`;
    }
    const powerWithYearsElement = item.querySelector('.userVehicle__powerWithYears');
    powerWithYearsElement.innerText = motorizationSuffix;

    // Update note element
    if (note) {
        const noteElement = item.querySelector('.userVehicle__note');
        noteElement.innerText = note;
    }

    // Update license plate or VIN elements
    if (licensePlate) {
        try {
            const licensePlateElement = item.querySelector('.userVehicle__licensePlate');
            const vehicleLicensePlateElement = licensePlateElement.querySelector('.vehicleLicensePlate');
            vehicleLicensePlateElement.dataset.licensePlate = licensePlate;
            licensePlateElement.classList.remove('--hidden');
        } catch (e) {
            console.error(e);
        }
    } else if (vin) {
        try {
            const vinElement = item.querySelector('.userVehicle__vin');
            const kvikVin = document.createElement('kvik-vin');
            kvikVin.innerText = vin;
            vinElement.appendChild(kvikVin);
            vinElement.classList.remove('--hidden');
        } catch (e) {
            console.error(e);
        }
    }

    // Initialize park vehicle button
    const parkVehicleElement = item.querySelector('.userVehicle__parkButton');
    parkVehicleElement.dataset.ajaxShowLoader = '1';
    parkVehicleElement.dataset.ajaxHref = template.dataset.parkVehicleUrl.replace(VEHICLE_ID_PLACEHOLDER, carId);
    window.kvik_ajax.initElement(parkVehicleElement);

    // Initialize parts button
    const partsElement = item.querySelector('.userVehicle__partsButton');
    partsElement.href = template.dataset.vehicleUrl.replace(VEHICLE_ID_PLACEHOLDER, carId).replace(USER_VEHICLE_ID_PLACEHOLDER, id);

    // Initialize edit button
    const editElement = item.querySelector('.userVehicle__editButton');
    editElement.dataset.ajaxShowLoader = '1';
    editElement.dataset.ajaxHref = template.dataset.userVehicleEditUrl.replace(USER_VEHICLE_ID_PLACEHOLDER, id).replace(VEHICLE_ID_PLACEHOLDER, carId);
    window.kvik_ajax.initElement(editElement);

    return item;
};

const initVehicleHistory = () => {
    const vehicleHistory = document.querySelector('.userVehicle__vehicleHistory');

    if (!vehicleHistory) {
        return;
    }

    if (vehicleHistory.dataset[DATA_INIT]) {
        return;
    } else {
        vehicleHistory.dataset[DATA_INIT] = '1';
    }

    // Delete vehicle history in case of outdated data
    deleteVehicleHistory();

    // Retrieve vehicle history data from local storage
    const vehicleHistoryData = JSON.parse(localStorage.getItem('vehicleHistory') || '[]');

    // If no data, remove the history element
    if (vehicleHistoryData.length === 0) {
        vehicleHistory.remove();
        return;
    }

    // Append vehicle history items
    const items = vehicleHistory.querySelector('.userVehicle__vehicleHistoryItems');
    for (const vehicle of vehicleHistoryData) {
        const item = createUserVehicleItem({...vehicle, name: '', motorization: vehicle.name});
        if (item) {
            items.appendChild(item);
        }
    }
};

const initDeleteVehicleHistory = () => {
    const deleteHistoryButton = document.querySelector('.userVehicle__vehicleHistoryDelete');
    const vehicleHistory = document.querySelector('.userVehicle__vehicleHistory');

    if (!deleteHistoryButton || !vehicleHistory) {
        return;
    }

    if (deleteHistoryButton.dataset[DATA_INIT]) {
        return;
    } else {
        deleteHistoryButton.dataset[DATA_INIT] = '1';
    }

    // Add click event listener to delete history button
    deleteHistoryButton.addEventListener('click', (event) => {
        // Add small delay to prevent immediate closure of the modal because of dimensions change (km_modal.js getBoundingClientRect)
        setTimeout(() => {
            event.preventDefault();

            // Add confirm
            if (!confirm(deleteHistoryButton.dataset.confirmMessage)) {
                return;
            }

            vehicleHistory.remove();
            localStorage.removeItem('vehicleHistory');
        }, 100);
    });
};

const initDeleteUserVehicle = () => {
    const deleteUserVehicleButton = document.querySelector('.userVehicle__delete');

    if (!deleteUserVehicleButton) {
        return;
    }

    if (deleteUserVehicleButton.dataset[DATA_INIT]) {
        return;
    } else {
        deleteUserVehicleButton.dataset[DATA_INIT] = '1';
    }

    // Add click event listener to delete user vehicle button
    deleteUserVehicleButton.addEventListener('click', async (event) => {
        event.preventDefault();

        // Prompt user for confirmation
        if (!confirm(deleteUserVehicleButton.dataset.confirmMessage)) {
            return;
        }

        try {
            // Delete user vehicle using fetch API
            await fetch(`/api/user-vehicle/delete`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: deleteUserVehicleButton.dataset.userVehicleId,
                }),
            });

            // Create a link to reload the user vehicle and trigger AJAX
            const userVehicleLink = document.createElement('a');
            userVehicleLink.href = deleteUserVehicleButton.dataset.userVehicleUrl;
            userVehicleLink.dataset.ajaxShowLoader = '1';
            userVehicleLink.dataset.ajaxHref = deleteUserVehicleButton.dataset.userVehicleUrl;
            userVehicleLink.style.display = 'none';
            document.body.appendChild(userVehicleLink);
            window.kvik_ajax.initElement(userVehicleLink);
            userVehicleLink.click();
        } catch (error) {
            console.error('Error deleting user vehicle:', error);
        }
    });
};

const initUserVehicleList = () => {
    const userVehicleList = document.querySelector('.userVehicle__itemList');

    if (!userVehicleList) {
        return;
    }

    if (userVehicleList.dataset[DATA_INIT]) {
        return;
    } else {
        userVehicleList.dataset[DATA_INIT] = '1';
    }

    const userVehicles = JSON.parse(userVehicleList.dataset.userVehicles);

    // Create user vehicle items
    for (const userVehicle of userVehicles) {
        const item = createUserVehicleItem({
            name: userVehicle.name,
            model: userVehicle.vehicle.vehicleModelName,
            manufacturer: userVehicle.vehicle.vehicleBrandName,
            motorization: userVehicle.vehicle.name,
            carId: userVehicle.vehicle.id,
            id: userVehicle.id,
            yearFrom: (new Date(userVehicle.vehicle.dateTimeFrom)).getFullYear().toString(),
            yearTo: userVehicle.vehicle.dateTimeTo ? (new Date(userVehicle.vehicle.dateTimeTo)).getFullYear().toString() : null,
            powerKw: userVehicle.vehicle.powerKW,
            motorCodes: userVehicle.vehicle.motorCodes,
            note: userVehicle.note,
            vin: userVehicle.vin,
            licensePlate: userVehicle.licensePlateNumber,
            vehicleBrandLogoUrl: userVehicle.vehicle.vehicleBrandLogoUrl,
        });

        userVehicleList.appendChild(item);
    }

    // Function to filter user vehicles
    const filterUserVehicles = (searchValue) => {
        searchValue = searchValue.replace(/[^a-zA-Z0-9]/g, '').toLowerCase(); // Clean up search value

        return userVehicles.filter(userVehicle => {
            const searchableValues = [
                userVehicle.name.toLowerCase(),
                userVehicle.vehicle.vehicleName.toLowerCase(),
                (userVehicle.vehicle.powerKW + 'kW').toLowerCase(),
                userVehicle.vehicle.motorCodes.map(motorCode => motorCode.toLowerCase()).join(','),
                userVehicle.vin.toLowerCase(),
                userVehicle.note.toLowerCase(),
                userVehicle.licensePlateNumber.toLowerCase(),
                (new Date(userVehicle.vehicle.dateTimeFrom)).getFullYear().toString(),
                (new Date(userVehicle.vehicle.dateTimeTo)).getFullYear().toString(),
            ];

            console.log(searchableValues);

            return searchableValues.some(value => value.includes(searchValue));
        });
    };

    // Apply filter to user vehicle list
    const applyFilter = (filteredIds) => {
        const items = userVehicleList.querySelectorAll('.userVehicle__item');
        items.forEach(item => {
            const itemId = parseInt(item.getAttribute('data-id'));
            if (filteredIds.includes(itemId)) {
                item.classList.remove('--hidden');
            } else {
                item.classList.add('--hidden');
            }
        });
    };

    // Add input event listener to search element
    const searchElement = document.querySelector('.userVehicle__searchInput');
    searchElement.addEventListener('input', (event) => {
        const searchValue = event.target.value.toLowerCase();
        const filteredUserVehicles = filterUserVehicles(searchValue);
        const filteredIds = filteredUserVehicles.map(userVehicle => userVehicle.id);

        applyFilter(filteredIds);
    });

    applyFilter(userVehicles.map(userVehicle => userVehicle.id));
};

const deleteVehicleHistory = () => {
    // Delete vehicle history in local storage if any of the items doesn't have a `vehicleBrandLogoUrl`
    const vehicleHistory = JSON.parse(localStorage.getItem('vehicleHistory') || '[]');
    for (const vehicle of vehicleHistory) {
        if (!vehicle.vehicleBrandLogoUrl) {
            localStorage.removeItem('vehicleHistory');
            return;
        }
    }
};

const initVehiclePreview = () => {
    const previewItemElement = document.querySelector('.userVehicle__previewItem');

    if (!previewItemElement) {
        return;
    }

    if (previewItemElement.dataset[DATA_INIT]) {
        return;
    } else {
        previewItemElement.dataset[DATA_INIT] = '1';
    }

    // Add vehicle details to preview item element
    if (previewItemElement.dataset.vehicle) {
        const vehicle = JSON.parse(previewItemElement.dataset.vehicle);
        const item = createUserVehicleItem({
            model: vehicle.vehicleModelName,
            manufacturer: vehicle.vehicleBrandName,
            motorization: vehicle.name,
            carId: vehicle.id,
            yearFrom: (new Date(vehicle.dateTimeFrom)).getFullYear().toString(),
            yearTo: vehicle.dateTimeTo ? (new Date(vehicle.dateTimeTo)).getFullYear().toString() : null,
            powerKw: vehicle.powerKW,
            motorCodes: vehicle.motorCodes,
            vehicleBrandLogoUrl: vehicle.vehicleBrandLogoUrl,
        });
        previewItemElement.appendChild(item);
    }

    // Add user vehicle details to preview item element
    if (previewItemElement.dataset.userVehicle) {
        const userVehicle = JSON.parse(previewItemElement.dataset.userVehicle);
        const item = createUserVehicleItem({
            name: userVehicle.name,
            model: userVehicle.vehicle.vehicleModelName,
            manufacturer: userVehicle.vehicle.vehicleBrandName,
            motorization: userVehicle.vehicle.name,
            carId: userVehicle.vehicle.id,
            id: userVehicle.id,
            yearFrom: (new Date(userVehicle.vehicle.dateTimeFrom)).getFullYear().toString(),
            yearTo: userVehicle.vehicle.dateTimeTo ? (new Date(userVehicle.vehicle.dateTimeTo)).getFullYear().toString() : null,
            powerKw: userVehicle.vehicle.powerKW,
            motorCodes: userVehicle.vehicle.motorCodes,
            note: userVehicle.note,
            vin: userVehicle.vin,
            licensePlate: userVehicle.licensePlateNumber,
            vehicleBrandLogoUrl: userVehicle.vehicle.vehicleBrandLogoUrl,
        });
        previewItemElement.appendChild(item);
    }
};

onDOMReady(() => {
    document.addEventListener(window.km_modal.EVENT_KM_MODAL_SHOW_MODAL, () => {
        initVehicleHistory();
        initVehiclePreview();
        initDeleteVehicleHistory();
        initDeleteUserVehicle();
        initUserVehicleList();
    });
});
