// jQuery free
import {EVENT_DROPDOWN_CLOSE, EVENT_DROPDOWN_OPEN} from './dropdown';
import {onDOMReady} from './dom';

const SCROLL_DELTA = 25;
const SCROLL_TOP_THRESHOLD = 100;
const CLASS_ACTIVE = '--active';
const CLASS_UP = '--up';
const CLASS_SCROLL_TOP = '--scrollTop';

let didScroll = false;
let lastScrollTop = 0;
let layoutHeader;
let headerHeight;

const scrolled = function () {
    const scrollTop = window.scrollY;

    if (Math.abs(lastScrollTop - scrollTop) <= SCROLL_DELTA) {
        return;
    }

    if (scrollTop > SCROLL_TOP_THRESHOLD) {
        layoutHeader?.classList.add(CLASS_SCROLL_TOP);
    } else {
        layoutHeader?.classList.remove(CLASS_SCROLL_TOP);
    }

    if (scrollTop > lastScrollTop && scrollTop > headerHeight) {
        layoutHeader?.classList.add(CLASS_UP);
    } else {
        if (scrollTop + window.innerHeight < document.documentElement.scrollHeight) {
            layoutHeader?.classList.remove(CLASS_UP);
        }
    }

    lastScrollTop = scrollTop;
};

document.addEventListener(EVENT_DROPDOWN_OPEN, () => {
    layoutHeader?.classList.add(CLASS_ACTIVE);
});

document.addEventListener(EVENT_DROPDOWN_CLOSE, () => {
    layoutHeader?.classList.remove(CLASS_ACTIVE);
});

onDOMReady(() => {
    layoutHeader = document.getElementById('layoutHeader');
    headerHeight = layoutHeader ? layoutHeader.offsetHeight : 0;

    window.addEventListener('scroll', () => {
        didScroll = true;
    });

    setInterval(() => {
        if (didScroll) {
            scrolled();
            didScroll = false;
        }
    }, 500);

    window.dispatchEvent(new Event('resize'));
});
