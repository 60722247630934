// jQuery free
import {onDOMReady} from './dom';

const CLASS_YEAR_SLIDER_INPUT = 'yearSlider__input';
const CLASS_YEAR_SLIDER_CURRENT = 'yearSlider__current';
const CLASS_YEAR_SLIDER_CURRENT_YEAR = 'yearSlider__currentYear';
const CSS_PROPERTY_YEAR_SLIDER_THUMB_WIDTH = '--sf-year-slider-thumb-width';
const DATA_YEAR_SLIDER_FOR = 'data-year-slider-for';
const DATA_YEARS = 'data-years';
const DATA_INIT = 'data-init';
const ATTR_HIDDEN = 'hidden';
const EVENT_YEAR_SLIDER_CHANGED = 'yearSliderChanged';

let lastYearSliderValue = null;

function initYearSlider(inputElement) {
    if (inputElement.hasAttribute(DATA_INIT)) {
        return;
    }

    inputElement.setAttribute(DATA_INIT, '1');

    const currentElement = inputElement.parentElement.getElementsByClassName(CLASS_YEAR_SLIDER_CURRENT)[0];
    const currentYearElement = inputElement.parentElement.getElementsByClassName(CLASS_YEAR_SLIDER_CURRENT_YEAR)[0];
    const contextElement = document.getElementById(inputElement.getAttribute(DATA_YEAR_SLIDER_FOR));
    const contextFilterableElements = contextElement?.querySelectorAll(`[${DATA_YEARS}]`) ?? [];

    const inputYearMin = parseInt(inputElement.getAttribute('min'));
    const inputYearMax = parseInt(inputElement.getAttribute('max'));
    const inputRange = inputYearMax - inputYearMin;
    const thumbWidth = parseInt(getComputedStyle(currentElement).getPropertyValue(CSS_PROPERTY_YEAR_SLIDER_THUMB_WIDTH)) || 1;

    const updateCurrent = () => {
        currentYearElement.innerHTML = inputElement.value;
        currentElement.style.left = 'calc(' + ((100 / inputRange) * (inputElement.value - inputYearMin)) + '% - ' + ((thumbWidth / inputRange) * (inputElement.value - inputYearMin)) + 'px)';
    };

    const doFiltration = () => {
        for (const element of contextFilterableElements) {
            const years = element.getAttribute(DATA_YEARS) || '';

            if (years.includes(inputElement.value)) {
                element.removeAttribute(ATTR_HIDDEN);
            } else {
                element.setAttribute(ATTR_HIDDEN, null);
            }
        }

        document.dispatchEvent(new CustomEvent(EVENT_YEAR_SLIDER_CHANGED, {
            detail: {
                year: inputElement.value
            }
        }));
        // ToDo: Show some info line when there are no models for specific year
    };

    if (lastYearSliderValue && lastYearSliderValue >= inputYearMin && lastYearSliderValue <= inputYearMax) {
        inputElement.value = lastYearSliderValue;
        updateCurrent();
        doFiltration();
    } else {
        updateCurrent();
        // intentionally after updateCurrent() to hide the year if the slider was not yet used
        currentYearElement.innerHTML = '';
    }


    inputElement.oninput = function () {
        updateCurrent();
        doFiltration();
        lastYearSliderValue = parseInt(inputElement.value);
    };
}

function initYearSlidersInDOM() {
    for (const inputElement of document.getElementsByClassName(CLASS_YEAR_SLIDER_INPUT)) {
        initYearSlider(inputElement);
    }
}

document.addEventListener(km_modal.EVENT_KM_MODAL_SHOW_MODAL, function () {
    initYearSlidersInDOM();
});


onDOMReady(() => {
    initYearSlidersInDOM();
});