// jQuery free
import {onDOMReady} from './dom';

const QUERY_SELECTIVE_SUPPLY_OPTION = '.productDetailAddToCart__supplyOption.--selective';
const CLASS_CURRENT = '--current';
const ID_FORM = 'productDetailAddToCart__form';
const ID_SELECT = 'productDetailAddToCart__supplyOptionSelect';
const DATA_INIT = 'data-init';
const DATA_PRICE_WITH_VAT = 'data-price-with-vat';
const DATA_PRICE_WITHOUT_VAT = 'data-price-without-vat';
const DATA_DISCOUNT_PRICE = 'data-discount-price';
const DATA_STRIKETHROUGH_PRICE = 'data-strikethrough-price';
const DATA_IS_DISPLAYED_WITHOUT_VAT = 'data-is-displayed-without-vat';


let formElement;
let selectElement;
let supplyIdInputElement;
const supplyOptionElements = [];


const initSupplyOption = function (supplyOptionElement) {
    if (supplyOptionElement.hasAttribute(DATA_INIT)) {
        return;
    }

    supplyOptionElement.setAttribute(DATA_INIT, '1');
    supplyOptionElements.push(supplyOptionElement);
    if (supplyOptionElement.hasAttribute('disabled')) {
        return;
    }

    supplyOptionElement.addEventListener('click', (event) => {
        event.preventDefault();
        supplyIdInputElement.value = supplyOptionElement.getAttribute('data-value');
        rerenderSupplyOptions();
        updateProductPrice(
            supplyOptionElement.getAttribute(DATA_PRICE_WITH_VAT),
            supplyOptionElement.getAttribute(DATA_PRICE_WITHOUT_VAT),
            supplyOptionElement.getAttribute(DATA_DISCOUNT_PRICE),
            supplyOptionElement.getAttribute(DATA_STRIKETHROUGH_PRICE),
            supplyOptionElement.getAttribute(DATA_IS_DISPLAYED_WITHOUT_VAT)
        );
    });
};


const initSelectElement = function (selectElement) {
    if (selectElement.hasAttribute(DATA_INIT)) {
        return;
    }
    selectElement.setAttribute(DATA_INIT, '1');

    supplyIdInputElement.value = selectElement.value;

    selectElement.addEventListener('change', () => {
        supplyIdInputElement.value = selectElement.value;
    });
};


const updateProductPrice = function (priceWithVat, priceWithoutVat, discountPrice, strikethroughPrice, isDisplayedWithoutVat) {
    const withVatElement = document.querySelector('.--yourPrice .price__primary');
    const withoutVatElement = document.querySelector('.--nonPreferred .price__primary');
    const discountElement = document.querySelector('.--youSave .price__primary');
    const strikethroughElement = document.querySelector('.--strikethrough .price__primary');

    if (withVatElement && priceWithVat) {
        withVatElement.innerHTML = isDisplayedWithoutVat ? priceWithoutVat : priceWithVat;
    }

    if (withoutVatElement && priceWithoutVat) {
        withoutVatElement.innerHTML = isDisplayedWithoutVat ? priceWithVat : priceWithoutVat;
    }

    if (discountElement && discountPrice) {
        discountElement.innerHTML = discountPrice;
    }

    if (strikethroughElement && strikethroughPrice) {
        strikethroughElement.innerHTML = strikethroughPrice;
    }
};


const rerenderSupplyOptions = function () {
    for (const supplyOptionElement of supplyOptionElements) {
        if (supplyIdInputElement.value.toString() === supplyOptionElement.getAttribute('data-value').toString()) {
            supplyOptionElement.classList.add(CLASS_CURRENT);
        } else {
            supplyOptionElement.classList.remove(CLASS_CURRENT);
        }
    }
};


onDOMReady(function () {
    formElement = document.getElementById(ID_FORM);
    if (!formElement) {
        return;
    }

    supplyIdInputElement = formElement.querySelector('[name=supplyId]');
    selectElement = document.getElementById(ID_SELECT);

    for (const supplyOptionElement of document.querySelectorAll(QUERY_SELECTIVE_SUPPLY_OPTION)) {
        initSupplyOption(supplyOptionElement);
    }

    if (selectElement) {
        initSelectElement(selectElement);
    }
});