// jQuery free
import {onDOMReady} from './dom';

const ID_FORM_RENAME = 'shoppingCartMultipleCart__formRename';
const CLASS_CART_BUTTON = 'shoppingCartMultipleCart__cartButton';
const CLASS_FORM_INPUT = 'shoppingCartMultipleCart__formInput';
const CLASS_FORM_BUTTON = 'shoppingCartMultipleCart__formButton';


const initShoppingCartMultipleCart = () => {
    const formRename = document.getElementById(ID_FORM_RENAME);
    if (!formRename) {
        return;
    }

    const cartButton = formRename.parentElement.getElementsByClassName(CLASS_CART_BUTTON)[0];
    const formInput = formRename.getElementsByClassName(CLASS_FORM_INPUT)[0];
    const formButton = formRename.getElementsByClassName(CLASS_FORM_BUTTON)[0];

    cartButton.addEventListener('click', (event) => {
        formRename.hidden = false;
        formInput.value = formInput.getAttribute('data-name');
        formInput.focus();
        event.stopPropagation();
    });

    formInput.addEventListener('blur', () => {
        formRename.hidden = true;
    });

    formInput.addEventListener('keyup', (e) => {
        if (e.key === 'Escape') {
            formInput.blur();
            formRename.hidden = true;
        }
    });

    formButton.addEventListener('mousedown', () => {
        formRename.submit();
    });
};


onDOMReady(initShoppingCartMultipleCart);