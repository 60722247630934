import {onDOMReady} from './dom';

onDOMReady(() => {
    const mobileSearchButton = document.getElementsByClassName('mobileAppNav__item--search')[0];

    if (mobileSearchButton) {
        const searchInput = document.getElementsByClassName('layoutHeaderSearch__input')[0];

        if (searchInput) {
            mobileSearchButton.addEventListener('click', () => {

                // Hack to focus input on mobile
                const fakeInput = document.createElement('input');
                fakeInput.setAttribute('type', 'text');
                fakeInput.style.position = 'absolute';
                fakeInput.style.opacity = 0;
                fakeInput.style.height = 0;
                fakeInput.style.fontSize = '16px'; // disable auto zoom

                // zoom/scroll behavior
                document.body.prepend(fakeInput)

                // focus so that subsequent async focus will work
                fakeInput.focus()

                setTimeout(() => {
                    searchInput.focus()
                    fakeInput.remove()
                }, 100);
            });
        }
    }
});