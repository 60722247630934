// jQuery free
import {onDOMReady} from './dom';

const KEY_VEHICLE_HISTORY = 'vehicleHistory';

/**
 * @typedef {Object} Vehicle
 * @property {string} manufacturerSlug
 * @property {string} model
 * @property {string} title
 * @property {string} motorization
 * @property {string} url
 * @property {int} carId
 * @property {int} manufacturer
 */

/**
 * @type {Vehicle[]}
 */
let vehicleHistory = [];

/**
 * @returns {null|Vehicle}
 */
const getCurrentVehicle = () => {
    const {KMPayload} = window;
    if (KMPayload && KMPayload.hasOwnProperty('tecdocCarId') === true) {
        const {
            tecdocCarId,
            tecdocCarManufacturer,
            tecdocCarManufacturerSlug,
            tecdocCarTitle,
            tecdocCarModel,
            tecdocCarMotorization,
            tecdocCarUrl,
            tecdocCarName,
            tecdocCarPowerKw,
            tecdocCarYearFrom,
            tecdocCarYearTo,
            tecdocCarMotorCodes,
            tecdocCarVehicleBrandLogoUrl
        } = KMPayload;
        return {
            carId: tecdocCarId,
            manufacturer: tecdocCarManufacturer,
            manufacturerSlug: tecdocCarManufacturerSlug,
            title: tecdocCarTitle,
            model: tecdocCarModel,
            motorization: tecdocCarMotorization,
            url: tecdocCarUrl,
            // New fields, might not be present in local storage
            name: tecdocCarName,
            powerKw: tecdocCarPowerKw,
            yearFrom: tecdocCarYearFrom,
            yearTo: tecdocCarYearTo,
            motorCodes: JSON.parse(tecdocCarMotorCodes),
            vehicleBrandLogoUrl: tecdocCarVehicleBrandLogoUrl
        };
    }
    return null;
};

/**
 * @returns {Vehicle[]}
 */
const loadVehicleHistory = () => {
    try {
        const vehicleHistory = JSON.parse(localStorage.getItem(KEY_VEHICLE_HISTORY) || '[]');
        if (Array.isArray(vehicleHistory)) {
            return vehicleHistory;
        }
    } catch (error) {
        console.error(error);
    }
    return [];
};

/**
 * @param vehicleHistory {Vehicle[]}
 */
const saveVehicleHistory = (vehicleHistory) => {
    try {
        localStorage.setItem(KEY_VEHICLE_HISTORY, JSON.stringify(vehicleHistory));
    } catch (error) {
        console.error(error);
    }
};

/**
 * @param vehicleHistory {Vehicle[]}
 * @param maxLimit {int}
 * @returns {Vehicle[]}
 */
const limitVehicleHistory = (vehicleHistory, maxLimit) => {
    if (vehicleHistory.length >= maxLimit) {
        return vehicleHistory.slice(0, maxLimit - 1);
    }
    return vehicleHistory;
};

/**
 *
 * @param vehicle {Vehicle|null}
 * @param vehicleHistory {Vehicle[]}
 * @returns {Vehicle[]}
 */
const putVehicleToVehicleHistory = (vehicle, vehicleHistory) => {
    if (vehicle) {
        vehicleHistory = vehicleHistory.filter((vehicleInHistory) => {
            return vehicle.carId !== vehicleInHistory.carId;
        });
        vehicleHistory.unshift(vehicle);
        saveVehicleHistory(vehicleHistory);
    }
    return vehicleHistory;
};

onDOMReady(() => {
    vehicleHistory = loadVehicleHistory();
    const currentVehicle = getCurrentVehicle();

    if (currentVehicle) {
        vehicleHistory = putVehicleToVehicleHistory(currentVehicle, vehicleHistory);
        vehicleHistory = limitVehicleHistory(vehicleHistory, 20);
        saveVehicleHistory(vehicleHistory);
    }
});