// jQuery free
import {getSelectedVehicle, getSelectedVehicleSearchResultDetail} from './payload';
import {ATTRIBUTE_DATA_LICENSE_PLATE, ELEMENT_NAME} from './vehicleLicensePlate';
import {onHtmlLayoutReady, requestIdleCallback} from './dom';

/**
 * @typedef {Object} VehicleResultData
 * @property {?Vehicle} vehicle
 * @property {?VehicleSearchResultDetail} vehicleSearchResultDetail
 * @property {?string} vehicleBrandLogoUrl
 * @property {bool} displayVehicleSearchResultDetailVin
 */

class VehicleResultElement extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        /** @type {VehicleResultData} */
        const data = JSON.parse(this.dataset.json);

        if (data.vehicle) {
            requestIdleCallback(() => this.render(data));
        } else {
            onHtmlLayoutReady(() => {
                data.vehicle = getSelectedVehicle();
                data.vehicleSearchResultDetail = getSelectedVehicleSearchResultDetail();
                this.render(data);
            });
        }
    }

    render(data) {
        if (!data.vehicle && !data.vehicleSearchResultDetail) {
            return;
        }

        // Do not display when vehicle type does not match
        if (data.displayWhenVehicleType && (data.vehicle.vehicleType !== data.displayWhenVehicleType)) {
            return;
        }

        const templateElement = this.getElementsByTagName('template')[0];
        const cloneNode = templateElement.content.cloneNode(true);
        this.appendChild(cloneNode);

        const linkElement = this.getElementsByClassName('vehicleResult__link')[0];
        linkElement.href = data.vehicleSearchResultDetail?.vinSlug ? ('/oem/' + data.vehicleSearchResultDetail?.vinSlug) : data.vehicle?.url;

        const titleElement = this.getElementsByClassName('vehicleResult__title')[0];
        titleElement.textContent = data.vehicle ? (data.vehicle.vehicleBrandName + ' ' + (data.vehicle.vehicleModelNameWithoutCodes || data.vehicle.vehicleModelName)) : (data.vehicleSearchResultDetail?.vehicleName);

        const motorizationElement = this.getElementsByClassName('vehicleResult__motorization')[0];
        motorizationElement.textContent = data.vehicle?.name ?? data.vehicleSearchResultDetail?.engine ?? '';

        const yearsElement = this.getElementsByClassName('vehicleResult__years')[0];
        yearsElement.textContent = data.vehicle?.yearsRange ?? '';

        const motorCodesElement = this.getElementsByClassName('vehicleResult__motorCodes')[0];
        const motorCodes = data.vehicle?.motorCodes?.join(', ');
        if (motorCodes?.length) {
            motorCodesElement.textContent = motorCodes;
            motorCodesElement.hidden = false;
        }

        const vehicleImgElement = this.getElementsByClassName('vehicleSearchResult__vehicleImg')[0];
        const brandLogoImgElement = this.getElementsByClassName('vehicleSearchResult__brandLogoImg')[0];
        const vehicleImageUrl = data.vehicle?.mainImage?.url;
        const brandLogoImageUrl = data.vehicle?.vehicleBrandLogoUrl ?? data.vehicleBrandLogoUrl;

        if (vehicleImageUrl) {
            vehicleImgElement.src = vehicleImageUrl;
            vehicleImgElement.alt = titleElement.textContent;
            vehicleImgElement.hidden = false;
        } else if (brandLogoImageUrl) {
            brandLogoImgElement.src = brandLogoImageUrl;
            brandLogoImgElement.alt = data.vehicle.vehicleBrandName ?? titleElement.textContent;
            brandLogoImgElement.hidden = false;
        }

        const vinElement = this.getElementsByClassName('vehicleResult__vin')[0];
        if (data.displayVehicleSearchResultDetailVin && data.vehicleSearchResultDetail?.vin) {
            vinElement.innerHTML = `<kvik-vin>${data.vehicleSearchResultDetail?.vin}</kvik-vin>`;
            vinElement.hidden = false;
        }

        const licensePlate = data.vehicleSearchResultDetail?.licensePlate;
        if (licensePlate) {
            const licensePlateElement = this.getElementsByClassName('vehicleResult__licensePlate')[0];
            const vehicleLicensePlateElement = this.getElementsByTagName(ELEMENT_NAME)[0];
            vehicleLicensePlateElement.setAttribute(ATTRIBUTE_DATA_LICENSE_PLATE, licensePlate);
            licensePlateElement.hidden = false;
        }

        this.removeAttribute('hidden');
    }
}

window.customElements.define('kvik-vehicle-result', VehicleResultElement);