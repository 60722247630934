// jQuery free
import {postVehicleSelectionUnselect} from './api';
import {onDOMReady} from './dom';
import {getSelectedVehicle, getSelectedVehicleType} from './payload';

const CLASS_JS_VEHICLE_UNSELECT = 'js__vehicleUnselect';



function initVehicleUnselect(vehicleUnselectButtonElement) {
    const isVehicleSelected = getSelectedVehicle();

    if (isVehicleSelected) {
        vehicleUnselectButtonElement.disabled = false;
        if (!vehicleUnselectButtonElement.dataset.displaywhenvehicletype || vehicleUnselectButtonElement.dataset.displaywhenvehicletype === getSelectedVehicleType()) {
            vehicleUnselectButtonElement.hidden = false;
        }
    }

    vehicleUnselectButtonElement.addEventListener('click', function () {
        window.km_modal.showLoader();
        postVehicleSelectionUnselect().then(() => {
            let url = new URL(window.location.toString());
            let params = new URLSearchParams(url.search);
            params.delete('do');

            window.location.href = url.pathname + '?' + params.toString();
        });
    });
}

onDOMReady(() => {
    for (const vehicleUnselectButtonElement of document.getElementsByClassName(CLASS_JS_VEHICLE_UNSELECT)) {
        initVehicleUnselect(vehicleUnselectButtonElement);
    }
});