// jQuery free
import {effectShakeElement} from './effects';
import searchAutocomplete from './searchAutocomplete';
import {onDOMReady} from './dom';

onDOMReady(() => {
    const searchForm = document.getElementsByClassName('layoutHeaderSearch')[0];

    if (!searchForm) {
        return;
    }

    const searchInput = searchForm.getElementsByTagName('input')[0];

    if (!searchInput) {
        return;
    }

    searchForm.onsubmit = (event) => {
        searchInput.value = searchInput.value.trim().replace(/\s/g, ' ');
        if (searchInput.value.length < 2) {
            event.preventDefault();
            effectShakeElement(searchForm);
        }
    };

    searchAutocomplete({searchForm, searchInput});

    const searchPhrase = window.KMPayload?.searchPhrase;
    if (searchPhrase) {
        searchInput.value = searchPhrase;
    }
});